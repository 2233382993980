/* eslint-disable no-param-reassign */
import produce from 'immer';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import storage from 'store/dist/store.modern';

import { COMMON } from '../common';
import { ROLE_STUDENT } from '../../features/common/enums';
import User from '../../models/User';

import * as api from './api';

import { MESSAGE_TYPES, actions as messageActions } from '../message';

import { CURRENT_USER, actions } from './actions';

export { actions };

const initialState = {
  user: null,
  activeSchoolId: null,
  loading: false,
  changePasswordSuccess: false,
  registerSuccess: false,
  resetPasswordSuccess: false,
  removeSelfSuccess: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.changePasswordSuccess = false;
        draft.registerSuccess = false;
        draft.resetPasswordSuccess = false;
        draft.error = null;
        break;
      case CURRENT_USER.FETCH:
        draft.loading = true;
        break;
      case CURRENT_USER.RECEIVE:
        draft.user = action.payload ? new User(action.payload) : null;
        draft.loading = false;
        draft.error = null;
        break;
      case CURRENT_USER.SET_ACTIVE_SCHOOL_ID:
        draft.activeSchoolId = action.payload;
        storage.set('activeSchoolId', action.payload);
        break;
      case CURRENT_USER.LOGIN:
        draft.loading = true;
        break;
      case CURRENT_USER.LOGIN_CODE:
        draft.loading = true;
        break;
      case CURRENT_USER.LOGIN_STUDENT:
        draft.loading = true;
        break;
      case CURRENT_USER.CLEAR:
        draft.user = null;
        break;
      case CURRENT_USER.CHANGE_PASSWORD:
        draft.loading = true;
        draft.changePasswordSuccess = false;
        draft.error = null;
        break;
      case CURRENT_USER.CHANGE_PASSWORD_SUCCESS:
        draft.loading = false;
        draft.changePasswordSuccess = true;
        draft.error = null;
        break;
      case CURRENT_USER.LINK_EXTERNAL:
        draft.loading = true;
        draft.error = null;
        break;
      case CURRENT_USER.LINK_EXTERNAL_SUCCESS:
        draft.loading = false;
        draft.error = null;
        break;
      case CURRENT_USER.REGISTER:
        draft.loading = true;
        draft.registerSuccess = false;
        break;
      case CURRENT_USER.REGISTER_SUCCESS:
        draft.loading = false;
        draft.registerSuccess = true;
        draft.error = null;
        break;
      case CURRENT_USER.RESET_PASSWORD:
        draft.loading = true;
        draft.resetPasswordSuccess = false;
        draft.error = null;
        break;
      case CURRENT_USER.RESET_PASSWORD_REQUEST:
        draft.loading = true;
        draft.resetPasswordSuccess = false;
        draft.error = null;
        break;
      case CURRENT_USER.RESET_PASSWORD_SUCCESS:
        draft.loading = false;
        draft.resetPasswordSuccess = true;
        draft.error = null;
        break;
      case CURRENT_USER.CLEAR_RESET_PASSWORD_SUCCESS:
        draft.resetPasswordSuccess = false;
        break;
      case CURRENT_USER.REMOVE_SELF:
        draft.loading = true;
        draft.removeSelfSuccess = false;
        draft.error = null;
        break;
      case CURRENT_USER.REMOVE_SELF_SUCCESS:
        draft.loading = false;
        draft.removeSelfSuccess = true;
        break;
      case CURRENT_USER.ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  currentUser: state => state.user.user,
  activeSchoolId: state => state.user.activeSchoolId,
  loading: state => state.user.loading,
  changePasswordSuccess: state => state.user.changePasswordSuccess,
  registerSuccess: state => state.user.registerSuccess,
  resetPasswordSuccess: state => state.user.resetPasswordSuccess,
  removeSelfSuccess: state => state.user.removeSelfSuccess,
  error: state => state.user.error,
};

function* setActiveSchool(user) {
  if (user.schools && user.schools.length) {
    const activeSchoolId = storage.get('activeSchoolId');
    const activeSchool = user.schools.find(s => s.id === activeSchoolId);

    if (!activeSchoolId || (activeSchoolId && !activeSchool)) {
      yield put(actions.setActiveSchoolId(user.schools[0].id));
    } else {
      yield put(actions.setActiveSchoolId(activeSchoolId));
    }
  } else if (storage.get('activeSchoolId')) {
    storage.remove('activeSchoolId');
  }
}

export function* fetchCurrentUser() {
  try {
    let user = null;
    if (storage.get('authenticated')) {
      user = yield call(api.fetchCurrentUser);
      yield call(setActiveSchool, user);
    }

    yield put(actions.receive(user));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* login(action) {
  try {
    const user = yield call(api.login, action.payload);
    storage.set('authenticated', true);
    yield call(setActiveSchool, user);

    yield put(actions.receive(user));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* loginExternal() {
  try {
    storage.set('authenticated', true);
    yield put(actions.fetch());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* loginCode(action) {
  try {
    const user = yield call(api.loginCode, action.payload);
    storage.set('authenticated', true);
    yield call(setActiveSchool, user);

    yield put(actions.receive(user));
    yield put(push('/users/me'));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* loginStudent(action) {
  try {
    const user = yield call(api.loginStudent, action.payload);
    storage.set('authenticated', true);
    yield put(actions.receive(user));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* logout(action) {
  try {
    const { initial, end } = action.payload;

    const user = yield select(selectors.currentUser);
    yield fork(api.logout);

    storage.clearAll();
    yield put(actions.clear());

    if (initial) {
      yield put(messageActions.send({ type: MESSAGE_TYPES.LOGOUT }));
    }

    const isStudent = user && user.roles.includes(ROLE_STUDENT);
    if (isStudent) {
      yield put(push(end ? '/auth/student?end=true' : '/'));
      return;
    }

    yield put(push('/'));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* changePassword(action) {
  try {
    const { oldPassword, newPassword } = action.payload;

    const user = yield call(api.changePassword, oldPassword, newPassword);
    yield put(actions.receive(user));
    yield put(actions.changePasswordSuccess());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* linkExternal(action) {
  try {
    const response = yield call(api.linkExternal, action.payload);
    yield put(actions.linkExternalSuccess());

    window.location.href = response.auth_url;
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* register(action) {
  try {
    yield call(api.register, action.payload);
    yield put(actions.registerSuccess());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* resetPassword(action) {
  try {
    yield call(api.resetPassword, action.payload);
    yield put(actions.resetPasswordSuccess());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* requestResetPassword(action) {
  try {
    yield call(api.resetPassword, action.payload);
    yield put(actions.resetPasswordSuccess());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeSelf() {
  try {
    yield call(api.removeSelf);
    yield put(actions.logout({ initial: true }));
    yield put(actions.removeSelfSuccess());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* userSaga() {
  yield all([
    yield takeLatest(CURRENT_USER.FETCH, fetchCurrentUser),
    yield takeLatest(CURRENT_USER.LOGIN, login),
    yield takeLatest(CURRENT_USER.LOGIN_EXTERNAL, loginExternal),
    yield takeLatest(CURRENT_USER.LOGIN_CODE, loginCode),
    yield takeLatest(CURRENT_USER.LOGIN_STUDENT, loginStudent),
    yield takeLatest(CURRENT_USER.LOGOUT, logout),
    yield takeLatest(CURRENT_USER.CHANGE_PASSWORD, changePassword),
    yield takeLatest(CURRENT_USER.LINK_EXTERNAL, linkExternal),
    yield takeLatest(CURRENT_USER.REGISTER, register),
    yield takeLatest(CURRENT_USER.RESET_PASSWORD, resetPassword),
    yield takeLatest(CURRENT_USER.RESET_PASSWORD_REQUEST, requestResetPassword),
    yield takeLatest(CURRENT_USER.REMOVE_SELF, removeSelf),
  ]);
}
