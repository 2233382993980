import api from '../api';

export function fetchChoices({ competitionId, seriesId, questionId, tagIds }) {
  return api.get(
    `/competitions/${competitionId}/series/${seriesId}/questions/${questionId}/choices`,
    {
      params: {
        tagIds,
      },
    }
  );
}

export function fetchChoice({ competitionId, seriesId, questionId, id }) {
  return api.get(
    `/competitions/${competitionId}/series/${seriesId}/questions/${questionId}/choices/${id}`
  );
}

export function createChoice({ competitionId, seriesId, questionId, ...data }) {
  return api.post(
    `/competitions/${competitionId}/series/${seriesId}/questions/${questionId}/choices`,
    data
  );
}

export function removeChoice({ competitionId, seriesId, questionId, id }) {
  return api.delete(
    `/competitions/${competitionId}/series/${seriesId}/questions/${questionId}/choices/${id}`
  );
}

export function updateChoice({
  competitionId,
  seriesId,
  questionId,
  id,
  ...updateData
}) {
  return api.put(
    `/competitions/${competitionId}/series/${seriesId}/questions/${questionId}/choices/${id}`,
    updateData
  );
}
