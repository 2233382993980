import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Spinner = ({ center, small, white }) => (
  <div
    className={classNames('spinner', {
      'spinner-center': center,
      'spinner-small': small,
      'spinner-white': white,
    })}
  >
    <div className="spinner-inner">
      <div className="spinner-double-bounce1" />
      <div className="spinner-double-bounce2" />
    </div>
  </div>
);

Spinner.propTypes = {
  center: PropTypes.bool,
  small: PropTypes.bool,
  white: PropTypes.bool,
};

Spinner.defaultProps = {
  center: false,
  small: false,
  white: false,
};

export default Spinner;
