import React, { Component, lazy } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider, FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';

import config from '../../config';
import { ROLES } from '../common/enums';
import Placeholder from '../common/Placeholder';
import SuspenseRoute from '../common/SuspenseRoute';
import ProtectedRoute from '../common/ProtectedRoute';

import { selectors as localeSelectors } from '../../store/locale';

import { actions, selectors } from '../../store/init';

import RootRoute from './RootRoute';
import Navigation from './Navigation';
import Breadcrumbs from './Breadcrumbs';
import ErrorBoundary from './ErrorBoundary';

import fi from '../../translations/fi.json';
import sv from '../../translations/sv.json';
import en from '../../translations/en.json';

const messages = { fi, sv, en };

const Home = lazy(() => import('../home'));
const Authentication = lazy(() => import('../authentication'));
const Dashboard = lazy(() => import('../management/dashboard'));
const ContentManagement = lazy(() => import('../management/contents'));
const UserManagement = lazy(() => import('../management/users'));
const CompetitionManagement = lazy(() => import('../management/competitions'));
const SchoolManagement = lazy(() => import('../management/schools'));
const TagManagement = lazy(() => import('../management/tags'));
const FileManagement = lazy(() => import('../management/files'));
const Student = lazy(() => import('../student'));

class App extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    init: PropTypes.func.isRequired,
    healthy: PropTypes.bool,
    initDone: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    healthy: null,
  };

  state = {
    statusMore: false,
  };

  componentDidMount() {
    this.props.init();
  }

  onStatusMore = e => {
    e.preventDefault();
    e.currentTarget.blur();

    this.setState(({ statusMore }) => ({ statusMore: !statusMore }));
  };

  renderRoutes = () => {
    return (
      <Switch>
        <SuspenseRoute
          path="/auth"
          lazyComponent={Authentication}
          fallback={Placeholder}
        />
        <SuspenseRoute
          path="/dashboard"
          lazyComponent={Dashboard}
          fallback={Placeholder}
          routeComponent={ProtectedRoute}
          roles={[ROLES.ADMIN, ROLES.ORGANIZER, ROLES.TEACHER]}
        />
        <SuspenseRoute
          path="/users"
          lazyComponent={UserManagement}
          fallback={Placeholder}
          routeComponent={ProtectedRoute}
          roles={[ROLES.ADMIN, ROLES.ORGANIZER, ROLES.TEACHER]}
        />
        <SuspenseRoute
          path="/competitions"
          lazyComponent={CompetitionManagement}
          fallback={Placeholder}
          routeComponent={ProtectedRoute}
          roles={[ROLES.ADMIN, ROLES.ORGANIZER, ROLES.TEACHER]}
        />
        <SuspenseRoute
          path="/schools"
          lazyComponent={SchoolManagement}
          fallback={Placeholder}
          routeComponent={ProtectedRoute}
          roles={[ROLES.ADMIN]}
        />
        <SuspenseRoute
          path="/tags"
          lazyComponent={TagManagement}
          fallback={Placeholder}
          routeComponent={ProtectedRoute}
          roles={[ROLES.ADMIN, ROLES.ORGANIZER]}
        />
        <SuspenseRoute
          path="/contents"
          lazyComponent={ContentManagement}
          fallback={Placeholder}
          routeComponent={ProtectedRoute}
          roles={[ROLES.ADMIN]}
        />
        <SuspenseRoute
          path="/files"
          lazyComponent={FileManagement}
          fallback={Placeholder}
          routeComponent={ProtectedRoute}
          roles={[ROLES.ADMIN]}
        />
        <SuspenseRoute
          path="/student"
          lazyComponent={Student}
          fallback={Placeholder}
        />
        <SuspenseRoute
          path="/kilpailu"
          lazyComponent={Student}
          fallback={Placeholder}
        />
        <SuspenseRoute
          path="/home"
          lazyComponent={Home}
          fallback={Placeholder}
          routeComponent={Route}
        />
        <SuspenseRoute
          exact
          path="/"
          lazyComponent={Home}
          fallback={Placeholder}
          routeComponent={Route}
        />
      </Switch>
    );
  };

  render() {
    const { healthy, initDone, location, locale } = this.props;
    const { statusMore } = this.state;

    if (!initDone) return null;

    const disableBreadcrumbs = [
      '/',
      '/auth/login',
      '/auth/register',
      '/auth/password-reset',
      '/auth/student',
    ];

    const helpDialog = ['/dashboard', '/users/mail'];

    const purePathname = location.pathname.replace(/[0-9a-f-]{36}/g, ':id');

    return (
      <IntlProvider
        locale={locale}
        messages={messages[locale]}
        defaultLocale="fi"
        textComponent="span"
      >
        <Sentry.ErrorBoundary
          fallback={({ error }) => <ErrorBoundary error={error} />}
        >
          <Navigation />

          {healthy === false && (
            <div className="container d-print-none">
              <div className="alert alert-danger">
                <FormattedMessage
                  id="status-unhealthy"
                  defaultMessage="Yhteys palvelimeen on tilapäisesti häiriintynyt. Sivusto ei todennäköisesti toimi oikein."
                />

                <a className="ml-2" href="#" onClick={this.onStatusMore}>
                  <i className="fas fa-info-circle fa-lg" />
                </a>

                {statusMore && (
                  <ul className="mt-2 mb-0">
                    <li>
                      <FormattedMessage
                        id="status-unhealthy-internet"
                        defaultMessage="Mikäli ongelma johtuu internet-yhteydestäsi, kokeile ladata sivu uudelleen"
                      />
                    </li>

                    <li>
                      <FormattedMessage
                        id="status-unhealthy-backend"
                        defaultMessage="Mikäli palvelin on alhaalla, pyrimme korjaamaan tilanteen mahdollisimman nopeasti. Voit kokeilla väliajoin ladata sivun uudelleen"
                      />
                    </li>

                    <li>
                      <FormattedMessage
                        id="status-unhealthy-support"
                        defaultMessage="Ongelman toistuessa ota yhteyttä {link}"
                        values={{
                          link: (
                            <a href={`mailto:${config.SUPPORT_EMAIL}`}>
                              <FormattedMessage
                                id="status-unhealthy-support-link"
                                defaultMessage="tukeen"
                              />
                            </a>
                          ),
                        }}
                      />
                    </li>
                  </ul>
                )}
              </div>
            </div>
          )}

          {!disableBreadcrumbs.includes(location.pathname) &&
            !location.pathname.startsWith('/student') &&
            !location.pathname.startsWith('/kilpailu') &&
            !location.pathname.startsWith('/home') &&
            !location.pathname.endsWith('/preview') && (
              <div className="container d-print-none mb-3">
                <div className="row">
                  <div className="col-auto">
                    <Breadcrumbs />
                  </div>

                  {helpDialog.includes(purePathname) && (
                    <div className="col-auto ml-auto">
                      <button
                        type="button"
                        className="btn btn-transparent text-primary my-0 py-2 px-3"
                        data-toggle="modal"
                        data-target="#helpDialog"
                      >
                        <i className="fas fa-question-circle fa-2x" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}

          <Switch>
            <RootRoute path="/" component={this.renderRoutes} />
          </Switch>
        </Sentry.ErrorBoundary>
      </IntlProvider>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      healthy: selectors.healthy(state),
      initDone: selectors.done(state),
      locale: localeSelectors.locale(state),
    }),
    dispatch =>
      bindActionCreators(
        {
          init: actions.init,
        },
        dispatch
      )
  )(App)
);
