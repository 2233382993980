import { LOCALES, LOCALE_FALLBACK } from '../features/common/enums';

import Tag from './Tag';

export default class Question {
  constructor(data) {
    Object.keys(data).forEach(k => {
      this[k] = data[k];
    });

    this.tags = data.tags.map(t => new Tag(t));
  }

  get correct_choice_lowercase() {
    if (!this.correct_choice) return null;

    const index = this.choice_ids.findIndex(
      choiceId => choiceId === this.correct_choice
    );

    if (index === -1) return null;

    return String.fromCharCode(97 + index);
  }

  get correct_choice_uppercase() {
    const letter = this.correct_choice_lowercase;
    return letter ? letter.toUpperCase() : letter;
  }

  needsFallback(locale) {
    if (this.descriptionNeedsFallback(locale)) return true;
    if (this.exampleNeedsFallback(locale)) return true;
    return false;
  }

  descriptionWithFallback(locale) {
    if (this.description[locale]) {
      return this.description[locale];
    }

    const fallback = LOCALE_FALLBACK.find(l => this.description[l]);
    return this.description[fallback] || '';
  }

  descriptionNeedsFallback(locale) {
    return !this.description[locale];
  }

  exampleWithFallback(locale) {
    if (this.example[locale]) {
      return this.example[locale];
    }

    const fallback = LOCALE_FALLBACK.find(l => this.example[l]);
    return this.example[fallback] || '';
  }

  exampleNeedsFallback(locale) {
    return !this.example[locale] && LOCALES.some(l => this.example[l]);
  }
}
