import { ROLES, ROLE_STUDENT } from '../features/common/enums';
import School from './School';

export default class User {
  constructor(data) {
    Object.keys(data).forEach(k => {
      this[k] = data[k];
    });

    if (this.schools) {
      this.schools = this.schools.map(s => new School(s));
    }
  }

  hasAccessToCompetition(competitionId) {
    return this.competition_permissions.includes(competitionId);
  }

  isManager() {
    return this.isAdmin() || this.isOrganizer() || this.isTeacher();
  }

  isAdmin() {
    return this.roles.includes(ROLES.ADMIN);
  }

  isOrganizer() {
    return this.roles.includes(ROLES.ORGANIZER);
  }

  isTeacher() {
    return this.roles.includes(ROLES.TEACHER);
  }

  isStudent() {
    return this.roles.includes(ROLE_STUDENT);
  }
}
