/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

const GROUPS = {
  FETCH_ALL: 'GROUPS/FETCH_ALL',
  RECEIVE_ALL: 'GROUPS/RECEIVE_ALL',
  FETCH: 'GROUPS/FETCH',
  RECEIVE: 'GROUPS/RECEIVE',
  FETCH_STATS: 'GROUPS/FETCH_STATS',
  RECEIVE_STATS: 'GROUPS/RECEIVE_STATS',
  CREATE: 'GROUPS/CREATE',
  CREATE_SUCCESS: 'GROUPS/CREATE_SUCCESS',
  CLEAR_CREATED: 'GROUPS/CLEAR_CREATED',
  REMOVE: 'GROUPS/REMOVE',
  REMOVE_SUCCESS: 'GROUPS/REMOVE_SUCCESS',
  UPDATE: 'GROUPS/UPDATE',
  UPDATE_SUCCESS: 'GROUPS/UPDATE_SUCCESS',
  UPDATE_STUDENT: 'GROUPS/UPDATE_STUDENT',
  UPDATE_STUDENT_SUCCESS: 'GROUPS/UPDATE_STUDENT_SUCCESS',
  ERROR: 'GROUPS/ERROR',
};

export const actions = {
  fetchAll: createAction(GROUPS.FETCH_ALL),
  receiveAll: createAction(GROUPS.RECEIVE_ALL),
  fetch: createAction(GROUPS.FETCH),
  receive: createAction(GROUPS.RECEIVE),
  fetchStats: createAction(GROUPS.FETCH_STATS),
  receiveStats: createAction(GROUPS.RECEIVE_STATS),
  create: createAction(GROUPS.CREATE),
  createSuccess: createAction(GROUPS.CREATE_SUCCESS),
  clearCreated: createAction(GROUPS.CLEAR_CREATED),
  remove: createAction(GROUPS.REMOVE),
  removeSuccess: createAction(GROUPS.REMOVE_SUCCESS),
  update: createAction(GROUPS.UPDATE),
  updateSuccess: createAction(GROUPS.UPDATE_STUDENT_SUCCESS),
  updateStudent: createAction(GROUPS.UPDATE_STUDENT),
  updateStudentSuccess: createAction(GROUPS.UPDATE_STUDENT_SUCCESS),
  error: createAction(GROUPS.ERROR),
};

const initialState = {
  groups: [],
  stats: {},
  createSuccess: false,
  createdGroup: null,
  loading: false,
  updateLoading: false,
  statsLoading: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.createSuccess = false;
        draft.createdGroup = null;
        draft.error = null;
        break;
      case GROUPS.FETCH_ALL:
        draft.loading = true;
        break;
      case GROUPS.RECEIVE_ALL:
        draft.groups = action.payload;
        draft.loading = false;
        draft.error = null;
        break;
      case GROUPS.FETCH:
        draft.loading = true;
        break;
      case GROUPS.RECEIVE: {
        draft.loading = false;

        const groups = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];

        groups.forEach(group => addItem(group, draft.groups));
        break;
      }
      case GROUPS.FETCH_STATS:
        draft.statsLoading = true;
        break;
      case GROUPS.RECEIVE_STATS:
        draft.stats[action.payload.group_id] = action.payload;
        draft.statsLoading = false;
        draft.error = null;
        break;
      case GROUPS.CREATE:
        draft.loading = true;
        draft.createSuccess = false;
        draft.createdGroup = null;
        break;
      case GROUPS.CREATE_SUCCESS:
        draft.loading = false;
        draft.createSuccess = true;
        draft.createdGroup = action.payload;
        addItem(action.payload, draft.groups);
        break;
      case GROUPS.CLEAR_CREATED:
        draft.createSuccess = false;
        draft.createdGroup = null;
        break;
      case GROUPS.REMOVE:
        draft.loading = true;
        break;
      case GROUPS.REMOVE_SUCCESS:
        draft.loading = false;
        removeItem(action.payload.id, draft.groups);
        break;
      case GROUPS.UPDATE:
        draft.loading = true;
        draft.updateLoading = true;
        break;
      case GROUPS.UPDATE_SUCCESS:
        draft.loading = false;
        draft.updateLoading = false;
        addItem(action.payload, draft.groups);
        break;
      case GROUPS.UPDATE_STUDENT:
        draft.loading = true;
        draft.updateLoading = true;
        break;
      case GROUPS.UPDATE_STUDENT_SUCCESS: {
        draft.loading = false;
        draft.updateLoading = false;

        const group = draft.groups.find(g => g.id === action.payload.group_id);
        if (!group) break;

        addItem(action.payload, group.students);
        break;
      }
      case GROUPS.ERROR:
        draft.loading = false;
        draft.updateLoading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  groups: state => state.groups.groups,
  stats: state => state.groups.stats,
  createSuccess: state => state.groups.createSuccess,
  createdGroup: state => state.groups.createdGroup,
  loading: state => state.groups.loading,
  updateLoading: state => state.groups.updateLoading,
  statsLoading: state => state.groups.statsLoading,
  error: state => state.groups.error,
};

export function* fetchGroups(action) {
  try {
    const groups = yield call(api.fetchGroups, action.payload);
    yield put(actions.receiveAll(groups));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchGroup(action) {
  try {
    const group = yield call(api.fetchGroup, action.payload);
    yield put(actions.receive(group));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchGroupStats(action) {
  try {
    const stats = yield call(api.fetchGroupStats, action.payload);
    yield put(actions.receiveStats(stats));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createGroup(action) {
  try {
    const group = yield call(api.createGroup, action.payload);
    yield put(actions.createSuccess(group));

    const { competitionId, seriesId } = action.payload;

    if (!action.payload.silent) {
      yield put(
        replace(
          `/competitions/${competitionId}/series/${seriesId}/groups/${group.id}`
        )
      );
    }
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeGroup(action) {
  try {
    yield call(api.removeGroup, action.payload);
    yield put(actions.removeSuccess(action.payload));

    const { competitionId, seriesId } = action.payload;
    yield put(replace(`/competitions/${competitionId}/series/${seriesId}`));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateGroup(action) {
  try {
    const groups = yield select(selectors.groups);
    const group = groups.find(u => u.id === action.payload.id);
    const updateData = {
      ...group,
      ...action.payload,
    };

    const updatedGroup = yield call(api.updateGroup, updateData);
    yield put(actions.updateSuccess(updatedGroup));

    const { competitionId, seriesId, id } = action.payload;
    yield put(
      replace(`/competitions/${competitionId}/series/${seriesId}/groups/${id}`)
    );
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateStudent(action) {
  try {
    const updatedStudent = yield call(api.updateStudent, action.payload);
    yield put(actions.updateStudentSuccess(updatedStudent));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* groupSaga() {
  yield all([
    yield takeLatest(GROUPS.FETCH_ALL, fetchGroups),
    yield takeLatest(GROUPS.FETCH, fetchGroup),
    yield takeLatest(GROUPS.FETCH_STATS, fetchGroupStats),
    yield takeEvery(GROUPS.CREATE, createGroup),
    yield takeEvery(GROUPS.REMOVE, removeGroup),
    yield takeEvery(GROUPS.UPDATE, updateGroup),
    yield takeEvery(GROUPS.UPDATE_STUDENT, updateStudent),
  ]);
}
