import axios from 'axios';
import storage from 'store/dist/store.modern';

import config from '../config';
import { actions } from './user/actions';

class APIError extends Error {
  constructor(status, message) {
    super();
    this.status = status;
    this.message = message;
  }
}

let store;

const api = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
});

/* eslint-disable no-param-reassign */
api.interceptors.request.use(requestConfig => {
  const token = storage.get('csrf');
  if (token) {
    requestConfig.headers['x-csrf-token'] = token;
  }

  return requestConfig;
});
/* eslint-enable no-param-reassign */

api.interceptors.response.use(
  response => {
    if (response.headers['x-csrf-token']) {
      storage.set('csrf', response.headers['x-csrf-token']);
    }

    if (Object.prototype.hasOwnProperty.call(response.data, 'total')) {
      return response.data;
    }

    if (Object.prototype.hasOwnProperty.call(response.data, 'data')) {
      return response.data.data;
    }

    return response.data;
  },
  error => {
    const { url } = error.response.config;

    if (error.response.status === 401 && !url.indexOf('/api/auth/') === -1) {
      if (store) store.dispatch(actions.logout({ initial: true }));
    }

    throw new APIError(error.response.status, error.response.data.error);
  }
);

export const applyStoreToApi = s => {
  store = s;
};

export default api;
