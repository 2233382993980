import { defineMessages } from 'react-intl';

export const LOCALES = ['fi', 'sv', 'en'];

export const LOCALE_TEXTS = defineMessages({
  fi: {
    id: 'locale-text-fi',
    defaultMessage: 'suomi',
  },
  sv: {
    id: 'locale-text-sv',
    defaultMessage: 'ruotsi',
  },
  en: {
    id: 'locale-text-en',
    defaultMessage: 'englanti',
  },
});

export const LOCALE_FALLBACK = ['en', 'fi', 'sv'];

export const ROLES = {
  ADMIN: 'admin',
  ORGANIZER: 'organizer',
  TEACHER: 'teacher',
};

export const ROLE_STUDENT = 'student';

export const ROLE_TEXTS = defineMessages({
  [ROLES.ADMIN]: {
    id: 'roles-admin',
    defaultMessage: 'Ylläpitäjä',
  },

  [ROLES.ORGANIZER]: {
    id: 'roles-organizer',
    defaultMessage: 'Kisajärjestäjä',
  },

  [ROLES.TEACHER]: {
    id: 'roles-teacher',
    defaultMessage: 'Opettaja',
  },
});

export const ROLE_COLORS = {
  [ROLES.ADMIN]: 'primary',
  [ROLES.ORGANIZER]: 'info',
  [ROLES.TEACHER]: 'default',
};

export const COMPETITION_TYPES = {
  KENGURU: 'kenguru',
  OTHER: 'other',
};

export const COMPETITION_TYPE_TEXTS = defineMessages({
  [COMPETITION_TYPES.KENGURU]: {
    id: 'competition-type-kenguru',
    defaultMessage: 'Kengurukilpailu',
  },
  [COMPETITION_TYPES.OTHER]: {
    id: 'competition-type-other',
    defaultMessage: 'Muu kilpailu',
  },
});

export const CONTENT_TYPES = {
  HOME_TITLE_DATE: 'home_title_date',
  HOME_SECTION: 'home_section',
  ARCHIVE_PDFS: 'archive_pdfs',
};

export const CONTENT_TYPE_META = {
  [CONTENT_TYPES.HOME_TITLE_DATE]: {
    multiple: false,
  },
  [CONTENT_TYPES.HOME_SECTION]: {
    multiple: true,
  },
  [CONTENT_TYPES.ARCHIVE_PDFS]: {
    multiple: false,
  },
};

export const CONTENT_TYPE_TEXTS = defineMessages({
  [CONTENT_TYPES.HOME_TITLE_DATE]: {
    id: 'content-type-home-title-date',
    defaultMessage: 'Etusivun otsikon päivämäärä',
  },
  [CONTENT_TYPES.HOME_SECTION]: {
    id: 'content-type-home-section',
    defaultMessage: 'Etusivun palsta',
  },
  [CONTENT_TYPES.ARCHIVE_PDFS]: {
    id: 'content-type-archive-pdfs',
    defaultMessage: 'Vanhat kisat PDF-muodossa -sivu',
  },
});

export const CONTENT_TYPE_PLURAL_TEXTS = defineMessages({
  [CONTENT_TYPES.HOME_SECTION]: {
    id: 'content-type-home-section-plural',
    defaultMessage: 'Etusivun palstat',
  },
});

export const PRACTICE_STUDENT_ID = '00000000-0000-0000-0000-000000000000';

export const CHOICE_LAYOUTS = {
  HORIZONTAL: 'horizontal',
  HORIZONTAL_STRETCHED: 'horizontal_stretched',
  STACKED: 'stacked',
  L_4_1: '4+1',
  L_4_1_STRETCHED: '4+1_stretched',
  L_3_2: '3+2',
  L_3_1_1: '3+1+1',
  L_2_2_1: '2+2+1',
  L_2_1_1_1: '2+1+1+1',
};

export const CHOICE_LAYOUT_TEXTS = defineMessages({
  [CHOICE_LAYOUTS.HORIZONTAL]: {
    id: 'choice-layout-horizontal',
    defaultMessage: 'Samalla rivillä tasavälein',
  },
  [CHOICE_LAYOUTS.HORIZONTAL_STRETCHED]: {
    id: 'choice-layout-horizontal-stretched',
    defaultMessage: 'Samalla rivillä venytetty',
  },
  [CHOICE_LAYOUTS.STACKED]: {
    id: 'choice-layout-stacked',
    defaultMessage: 'Päällekkäin',
  },
  [CHOICE_LAYOUTS.L_4_1]: {
    id: 'choice-layout-4-1',
    defaultMessage: '4+1',
  },
  [CHOICE_LAYOUTS.L_4_1_STRETCHED]: {
    id: 'choice-layout-4-1-stretched',
    defaultMessage: '4+1 venytetty',
  },
  [CHOICE_LAYOUTS.L_3_2]: {
    id: 'choice-layout-3-2',
    defaultMessage: '3+2',
  },
  [CHOICE_LAYOUTS.L_3_1_1]: {
    id: 'choice-layout-3-1-1',
    defaultMessage: '3+1+1',
  },
  [CHOICE_LAYOUTS.L_2_2_1]: {
    id: 'choice-layout-2-2-1',
    defaultMessage: '2+2+1',
  },
  [CHOICE_LAYOUTS.L_2_1_1_1]: {
    id: 'choice-layout-2-1-1-1',
    defaultMessage: '2+1+1+1',
  },
});

export const EXTERNAL_PROVIDERS = {
  MPASS: 'mpass',
};

export const EXTERNAL_PROVIDER_TEXTS = defineMessages({
  [EXTERNAL_PROVIDERS.MPASS]: {
    id: 'external-provider-mpass',
    defaultMessage: 'MPASSid',
  },
});
