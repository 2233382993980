import api from '../api';

export function fetchSchools({ name, code, fuzzy, page, limit }) {
  return api.get('/schools', {
    params: {
      name,
      code,
      fuzzy,
      page,
      limit,
    },
  });
}

export function fetchSchool({ id }) {
  return api.get(`/schools/${id}`);
}

export function fetchSchoolStats({ competitionId, seriesId, id }) {
  return api.get(`/competitions/${competitionId}/series/${seriesId}/stats`, {
    params: {
      school_id: id,
    },
  });
}

export function createSchool({ name, code, municipality }) {
  return api.post('/schools', { name, code, municipality });
}

export function removeSchool({ id }) {
  return api.delete(`/schools/${id}`);
}

export function updateSchool({ id, name, code, municipality }) {
  return api.put(`/schools/${id}`, { name, code, municipality });
}

export function addUserToSchool({ id, ...data }) {
  return api.post(`/schools/${id}/users`, data);
}

export function removeUserFromSchool({ id, userId }) {
  return api.delete(`/schools/${id}/users/${userId}`);
}
