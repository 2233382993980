import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from 'react-router-dom';

import { actions } from '../../store/common';

/**
 * Protected route. Define roles allowed to access
 */
const RootRoute = ({ component: Component, onNavigate, ...rest }) => {
  let RouteComponent = null;

  onNavigate();

  if (Component) RouteComponent = props => <Component {...props} />;
  else if (typeof rest.render === 'function') RouteComponent = rest.render;
  else throw new Error('Nothing to render in root route!');

  return <Route {...rest} render={RouteComponent} />;
};

RootRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
  render: PropTypes.func,
  location: PropTypes.object.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

RootRoute.defaultProps = {
  component: null,
  render: null,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onNavigate: actions.onNavigate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RootRoute);
