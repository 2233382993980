import api from '../api';

export function fetchUsers({
  username,
  school_id,
  school_name,
  is_verified,
  external_providers,
  roles,
  page,
  limit,
}) {
  return api.get('/users', {
    params: {
      username,
      school_id,
      school_name,
      is_verified,
      external_providers,
      roles,
      page,
      limit,
    },
  });
}

export function fetchUser({ id, include }) {
  return api.get(`/users/${id}`, { params: { include } });
}

export function createUser(username) {
  return api.post('/users', { username });
}

export function removeUser(userId) {
  return api.delete(`/users/${userId}`);
}

export function updateUser(id, userData) {
  return api.put(`/users/${id}`, {
    password: userData.password || '',
    roles: userData.roles,
    competition_permissions: userData.competition_permissions,
    is_verified: userData.is_verified,
  });
}

export function impersonateUser(userId) {
  return api.post(`/users/${userId}/impersonate`);
}

export function rejectUser(id) {
  return api.delete(`/users/${id}`, {
    params: { reject: true },
  });
}

export function sendMail(data) {
  return api.post('/users/mail', data);
}
