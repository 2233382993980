/* eslint-disable no-param-reassign */
import { all, put, call, take, takeLatest } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import produce from 'immer';

import { actions as userActions } from '../user';

import * as api from './api';

const INIT = {
  INIT: 'INIT',
  INIT_STATUS: 'INIT_STATUS',
  INIT_DONE: 'INIT_DONE',
};

export const actions = {
  init: createAction(INIT.INIT),
  initStatus: createAction(INIT.INIT_STATUS),
  initDone: createAction(INIT.INIT_DONE),
};

const initialState = {
  healthy: null,
  done: false,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case INIT.INIT_STATUS:
        draft.healthy = action.payload;
        break;
      case INIT.INIT_DONE:
        draft.done = true;
        break;
      default:
        break;
    }
  });
export default reducer;

export const selectors = {
  healthy: state => state.init.healthy,
  done: state => state.init.done,
};

function* init() {
  try {
    const status = yield call(api.status);
    yield put(actions.initStatus(status.status === 'healthy'));
  } catch (error) {
    yield put(actions.initStatus(false));
  }

  try {
    yield put(userActions.fetch());
    yield take([userActions.receive, userActions.error]);
    yield put(actions.initDone());
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
}

export function* initSaga() {
  yield all([yield takeLatest(INIT.INIT, init)]);
}
