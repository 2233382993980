import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import {
  actions as competitionsActions,
  selectors as competitionsSelectors,
} from '../../store/competitions';

import {
  actions as seriesesActions,
  selectors as seriesesSelectors,
} from '../../store/serieses';

import {
  actions as filesActions,
  selectors as filesSelectors,
} from '../../store/files';

import {
  actions as questionsActions,
  selectors as questionsSelectors,
} from '../../store/questions';

import {
  actions as schoolsActions,
  selectors as schoolsSelectors,
} from '../../store/schools';

import {
  actions as groupsActions,
  selectors as groupsSelectors,
} from '../../store/groups';

import {
  actions as usersActions,
  selectors as usersSelectors,
} from '../../store/users';

import LocalizedBreadcrumb from './LocalizedBreadcrumb';
import withFetcher from './WithFetcher';

const messages = defineMessages({
  home: {
    id: 'breadcrumb-home',
    defaultMessage: 'Etusivu',
  },
  dashboard: {
    id: 'breadcrumb-dashboard',
    defaultMessage: 'Hallintapaneeli',
  },
  contents: {
    id: 'breadcrumb-contents',
    defaultMessage: 'Sisällönhallinta',
  },
  contentsNew: {
    id: 'breadcrumb-contents-new',
    defaultMessage: 'Luo uusi sisältö',
  },
  contentsView: {
    id: 'breadcrumb-contents-view',
    defaultMessage: 'Katsele sisältöä',
  },
  contentsEdit: {
    id: 'breadcrumb-contents-edit',
    defaultMessage: 'Muokkaa sisältöä',
  },
  competitions: {
    id: 'breadcrumb-competitions',
    defaultMessage: 'Kilpailut',
  },
  competitionsNew: {
    id: 'breadcrumb-competitions-new',
    defaultMessage: 'Luo uusi kilpailu',
  },
  competitionsEdit: {
    id: 'breadcrumb-competitions-edit',
    defaultMessage: 'Muokkaa kilpailua',
  },
  competitionsSchools: {
    id: 'breadcrumb-competitions-schools',
    defaultMessage: 'Osallistuneet koulut',
  },
  competitionsMail: {
    id: 'breadcrumb-competitions-mail',
    defaultMessage: 'Lähetä sähköpostia',
  },
  groupsNew: {
    id: 'breadcrumb-groups-new',
    defaultMessage: 'Luo uusi ryhmä',
  },
  groupsEdit: {
    id: 'breadcrumb-groups-edit',
    defaultMessage: 'Muokkaa ryhmää',
  },
  groupsStats: {
    id: 'breadcrumb-groups-stats',
    defaultMessage: 'Tilastot',
  },
  answersNew: {
    id: 'breadcrumb-answers-new',
    defaultMessage: 'Vastausten kirjaus',
  },
  questionsNew: {
    id: 'breadcrumb-questions-new',
    defaultMessage: 'Luo uusi kysymys',
  },
  questionsEdit: {
    id: 'breadcrumb-questions-edit',
    defaultMessage: 'Muokkaa kysymystä',
  },
  seriesesNew: {
    id: 'breadcrumb-serieses-new',
    defaultMessage: 'Luo sarja',
  },
  seriesesEdit: {
    id: 'breadcrumb-serieses-edit',
    defaultMessage: 'Muokkaa sarjaa',
  },
  seriesesStats: {
    id: 'breadcrumb-serieses-stats',
    defaultMessage: 'Tilastot',
  },
  files: {
    id: 'breadcrumb-files',
    defaultMessage: 'Tiedostohallinta',
  },
  filesNew: {
    id: 'breadcrumb-files-new',
    defaultMessage: 'Luo tiedosto',
  },
  filesEdit: {
    id: 'breadcrumb-files-edit',
    defaultMessage: 'Muokkaa tiedostoa',
  },
  schools: {
    id: 'breadcrumb-schools',
    defaultMessage: 'Koulut',
  },
  schoolsNew: {
    id: 'breadcrumb-schools-new',
    defaultMessage: 'Luo koulu',
  },
  schoolsEdit: {
    id: 'breadcrumb-schools-edit',
    defaultMessage: 'Muokkaa koulua',
  },
  tags: {
    id: 'breadcrumb-tags',
    defaultMessage: 'Kysymysten avainsanat',
  },
  users: {
    id: 'breadcrumb-users',
    defaultMessage: 'Käyttäjät',
  },
  usersProfile: {
    id: 'breadcrumb-users-profile',
    defaultMessage: 'Oma käyttäjäsi',
  },
  usersNew: {
    id: 'breadcrumb-users-new',
    defaultMessage: 'Luo uusi käyttäjä',
  },
  usersMail: {
    id: 'breadcrumb-users-mail',
    defaultMessage: 'Lähetä sähköpostia',
  },
  usersVerifications: {
    id: 'breadcrumb-users-verifications',
    defaultMessage: 'Vahvistaminen',
  },
});

const routes = [
  {
    path: '/',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.home,
  },
  {
    path: '/dashboard',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.dashboard,
  },
  {
    path: '/contents',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.contents,
  },
  {
    path: '/contents/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.contentsNew,
  },
  {
    path: '/contents/:id',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.contentsView,
  },
  {
    path: '/contents/:id/edit',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.contentsEdit,
  },
  {
    path: '/competitions',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.competitions,
  },
  {
    path: '/competitions/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.competitionsNew,
  },
  {
    path: '/competitions/:id',
    breadcrumb: withFetcher(
      ({ item }) => <span>{item.name}</span>,
      competitionsActions.fetch,
      (fetch, params) => fetch(params.id),
      competitionsSelectors.competitions
    ),
  },
  {
    path: '/competitions/:id/edit',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.competitionsEdit,
  },
  {
    path: '/competitions/:id/schools',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.competitionsSchools,
  },
  {
    path: '/competitions/:id/mail',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.competitionsMail,
  },
  {
    path: '/competitions/:competitionId/series/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.seriesesNew,
  },
  {
    path: '/competitions/:competitionId/series/:id',
    breadcrumb: withFetcher(
      ({ item }) => <span>{item.name}</span>,
      seriesesActions.fetch,
      (fetch, { competitionId, id }) => fetch({ competitionId, id }),
      seriesesSelectors.serieses
    ),
  },
  {
    path: '/competitions/:competitionId/series/:id/edit',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.seriesesEdit,
  },
  {
    path: '/competitions/:competitionId/series/:id/stats',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.seriesesStats,
  },
  {
    path: '/competitions/:competitionId/series/:seriesId/groups/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.groupsNew,
  },
  {
    path: '/competitions/:competitionId/series/:seriesId/groups/:id',
    breadcrumb: withFetcher(
      ({ item }) => <span>{item.name}</span>,
      groupsActions.fetch,
      (fetch, { competitionId, seriesId, id }) =>
        fetch({ competitionId, seriesId, id }),
      groupsSelectors.groups
    ),
  },
  {
    path: '/competitions/:competitionId/series/:seriesId/groups/:id/edit',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.groupsEdit,
  },
  {
    path: '/competitions/:competitionId/series/:seriesId/groups/:id/stats',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.groupsStats,
  },
  {
    path:
      '/competitions/:competitionId/series/:seriesId/groups/:groupId/answers/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.answersNew,
  },
  {
    path:
      '/competitions/:competitionId/series/:seriesId/groups/:id/students/:studentId',
    breadcrumb: withFetcher(
      ({ item, params }) => (
        <span>{item.students.find(s => s.id === params.studentId).name}</span>
      ),
      groupsActions.fetch,
      (fetch, { competitionId, seriesId, id }) =>
        fetch({ competitionId, seriesId, id }),
      groupsSelectors.groups
    ),
  },
  {
    path: '/competitions/:competitionId/series/:seriesId/questions/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.questionsNew,
  },
  {
    path: '/competitions/:competitionId/series/:seriesId/questions/:id',
    breadcrumb: withFetcher(
      ({ item }) => (
        <FormattedMessage
          id="breadcrumbs-questions-view"
          defaultMessage="Kysymys #{number}"
          values={{
            number: item.number,
          }}
        />
      ),
      questionsActions.fetch,
      (fetch, { competitionId, seriesId, id }) =>
        fetch({ competitionId, seriesId, id }),
      questionsSelectors.questions
    ),
  },
  {
    path: '/competitions/:competitionId/series/:seriesId/questions/:id/edit',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.questionsEdit,
  },
  {
    path: '/competitions/:competitionId/files/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.filesNew,
  },
  {
    path: '/competitions/:competitionId/files/:id',
    breadcrumb: withFetcher(
      ({ item }) => <span>{item.filename}</span>,
      filesActions.fetch,
      (fetch, { competitionId, id }) => fetch({ competitionId, id }),
      filesSelectors.files
    ),
  },
  {
    path: '/competitions/:competitionId/files/:id/edit',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.filesEdit,
  },
  {
    path: '/files',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.files,
  },
  {
    path: '/files/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.filesNew,
  },
  {
    path: '/files/:id',
    breadcrumb: withFetcher(
      ({ item }) => <span>{item.filename}</span>,
      filesActions.fetch,
      (fetch, { id }) => fetch({ id }),
      filesSelectors.files
    ),
  },
  {
    path: '/files/:id/edit',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.filesEdit,
  },
  {
    path: '/schools',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.schools,
  },
  {
    path: '/schools/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.schoolsNew,
  },
  {
    path: '/schools/:id',
    breadcrumb: withFetcher(
      ({ item }) => <span>{item.name}</span>,
      schoolsActions.fetch,
      (fetch, { id }) => fetch({ id }),
      schoolsSelectors.schools
    ),
  },
  {
    path: '/schools/:id/edit',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.schoolsEdit,
  },
  {
    path: '/tags',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.tags,
  },
  {
    path: '/users/me',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.usersProfile,
  },
  {
    path: '/users',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.users,
  },
  {
    path: '/users/new',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.usersNew,
  },
  {
    path: '/users/mail',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.usersMail,
  },
  {
    path: '/users/verifications',
    breadcrumb: LocalizedBreadcrumb,
    message: messages.usersVerifications,
  },
  {
    path: '/users/:id',
    breadcrumb: withFetcher(
      ({ item }) => <span>{item.username}</span>,
      usersActions.fetch,
      (fetch, { id }) => fetch({ id }),
      usersSelectors.users
    ),
  },
  {
    path: '/home/competitions/:id',
    breadcrumb: withFetcher(
      ({ item }) => <span>{item.name}</span>,
      competitionsActions.fetch,
      (fetch, params) => fetch(params.id),
      competitionsSelectors.competitions
    ),
  },
];

class Breadcrumbs extends Component {
  static propTypes = {
    breadcrumbs: PropTypes.array.isRequired,
  };

  static defaultProps = {};

  render() {
    const { breadcrumbs } = this.props;

    return (
      <ol className="breadcrumb bg-white mb-0">
        {breadcrumbs.map(({ location, match, breadcrumb }) =>
          location.pathname === '/users/me' && match.url === '/users' ? null : (
            <li key={match.url} className="breadcrumb-item">
              {location.pathname === match.url ? (
                <span>{breadcrumb}</span>
              ) : (
                <Link to={match.url}>{breadcrumb}</Link>
              )}
            </li>
          )
        )}
      </ol>
    );
  }
}

export default withBreadcrumbs(routes, { disableDefaults: true })(Breadcrumbs);
