export default class School {
  constructor(data) {
    Object.keys(data).forEach(k => {
      this[k] = data[k];
    });
  }

  displayName() {
    return `${this.name} (${this.municipality || this.code})`;
  }
}
