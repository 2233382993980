import { createAction } from 'redux-actions';

export const CURRENT_USER = {
  FETCH: 'USER/FETCH',
  RECEIVE: 'USER/RECEIVE',
  SET_ACTIVE_SCHOOL_ID: 'USER/SET_ACTIVE_SCHOOL_ID',
  CLEAR: 'USER/CLEAR',
  LOGIN: 'USER/LOGIN',
  LOGIN_EXTERNAL: 'USER/LOGIN_EXTERNAL',
  LOGIN_CODE: 'USER/LOGIN_CODE',
  LOGIN_STUDENT: 'USER/LOGIN_STUDENT',
  LOGOUT: 'USER/LOGOUT',
  CHANGE_PASSWORD: 'USER/CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'USER/CHANGE_PASSWORD_SUCCESS',
  LINK_EXTERNAL: 'USER/LINK_EXTERNAL',
  LINK_EXTERNAL_SUCCESS: 'USER/LINK_EXTERNAL_SUCCESS',
  REGISTER: 'USER/REGISTER',
  REGISTER_SUCCESS: 'USER/REGISTER_SUCCESS',
  RESET_PASSWORD: 'USER/RESET_PASSWORD',
  RESET_PASSWORD_REQUEST: 'USER/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USER/RESET_PASSWORD_SUCCESS',
  CLEAR_RESET_PASSWORD_SUCCESS: 'USER/CLEAR_RESET_PASSWORD_SUCCESS',
  REMOVE_SELF: 'USER/REMOVE_SELF',
  REMOVE_SELF_SUCCESS: 'USER/REMOVE_SELF_SUCCESS',
  ERROR: 'USER/ERROR',
};

export const actions = {
  fetch: createAction(CURRENT_USER.FETCH),
  receive: createAction(CURRENT_USER.RECEIVE),
  setActiveSchoolId: createAction(CURRENT_USER.SET_ACTIVE_SCHOOL_ID),
  clear: createAction(CURRENT_USER.CLEAR),
  login: createAction(CURRENT_USER.LOGIN),
  loginExternal: createAction(CURRENT_USER.LOGIN_EXTERNAL),
  loginCode: createAction(CURRENT_USER.LOGIN_CODE),
  loginStudent: createAction(CURRENT_USER.LOGIN_STUDENT),
  logout: createAction(CURRENT_USER.LOGOUT),
  changePassword: createAction(CURRENT_USER.CHANGE_PASSWORD),
  changePasswordSuccess: createAction(CURRENT_USER.CHANGE_PASSWORD_SUCCESS),
  linkExternal: createAction(CURRENT_USER.LINK_EXTERNAL),
  linkExternalSuccess: createAction(CURRENT_USER.LINK_EXTERNAL_SUCCESS),
  register: createAction(CURRENT_USER.REGISTER),
  registerSuccess: createAction(CURRENT_USER.REGISTER_SUCCESS),
  resetPassword: createAction(CURRENT_USER.RESET_PASSWORD),
  resetPasswordRequest: createAction(CURRENT_USER.RESET_PASSWORD_REQUEST),
  resetPasswordSuccess: createAction(CURRENT_USER.RESET_PASSWORD_SUCCESS),
  clearResetPasswordSuccess: createAction(
    CURRENT_USER.CLEAR_RESET_PASSWORD_SUCCESS
  ),
  removeSelf: createAction(CURRENT_USER.REMOVE_SELF),
  removeSelfSuccess: createAction(CURRENT_USER.REMOVE_SELF_SUCCESS),
  error: createAction(CURRENT_USER.ERROR),
};
