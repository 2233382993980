import api from '../api';

export function fetchContents(params) {
  return api.get('/contents', {
    params,
  });
}

export function fetchContent(id) {
  return api.get(`/contents/${id}`);
}

export function createContent(data) {
  return api.post('/contents', data);
}

export function removeContent(id) {
  return api.delete(`/contents/${id}`);
}

export function updateContent(id, updateData) {
  return api.put(`/contents/${id}`, updateData);
}

export function updateContentOrder(data) {
  return api.put('/contents/order', data);
}
