import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { selectors } from '../../store/user';
import { UserPropType } from './types';

/**
 * Protected route. Define roles allowed to access
 */
const ProtectedRoute = ({ component: Component, user, roles, ...rest }) => {
  let RouteComponent = null;

  if (!user) {
    RouteComponent = props => (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: props.location },
        }}
      />
    );
  } else {
    const hasRequiredRole = user.roles.some(role => roles.includes(role));

    if (
      !user.has_password &&
      !user.external_id &&
      rest.location.pathname !== '/users/me'
    ) {
      RouteComponent = props => (
        <Redirect
          to={{
            pathname: '/users/me',
            state: { from: props.location },
          }}
        />
      );
    } else if (!hasRequiredRole) {
      RouteComponent = props => (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      );
    }
  }

  if (!RouteComponent) {
    if (Component) RouteComponent = props => <Component {...props} />;
    else if (typeof rest.render === 'function') RouteComponent = rest.render;
    else throw new Error('Nothing to render in private route!');
  }

  return <Route {...rest} render={RouteComponent} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
    PropTypes.elementType,
  ]),
  render: PropTypes.func,
  location: PropTypes.object.isRequired,
  user: UserPropType,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ProtectedRoute.defaultProps = {
  component: null,
  render: null,
  user: null,
};

export default connect(state => ({ user: selectors.currentUser(state) }))(
  ProtectedRoute
);
