/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

import Tag from '../../models/Tag';

const TAGS = {
  FETCH_ALL: 'TAGS/FETCH_ALL',
  RECEIVE_ALL: 'TAGS/RECEIVE_ALL',
  FETCH: 'TAGS/FETCH',
  RECEIVE: 'TAGS/RECEIVE',
  CREATE: 'TAGS/CREATE',
  CREATE_SUCCESS: 'TAGS/CREATE_SUCCESS',
  REMOVE: 'TAGS/REMOVE',
  REMOVE_SUCCESS: 'TAGS/REMOVE_SUCCESS',
  UPDATE: 'TAGS/UPDATE',
  UPDATE_SUCCESS: 'TAGS/UPDATE_SUCCESS',
  ERROR: 'TAGS/ERROR',
};

export const actions = {
  fetchAll: createAction(TAGS.FETCH_ALL),
  receiveAll: createAction(TAGS.RECEIVE_ALL),
  fetch: createAction(TAGS.FETCH),
  receive: createAction(TAGS.RECEIVE),
  create: createAction(TAGS.CREATE),
  createSuccess: createAction(TAGS.CREATE_SUCCESS),
  remove: createAction(TAGS.REMOVE),
  removeSuccess: createAction(TAGS.REMOVE_SUCCESS),
  update: createAction(TAGS.UPDATE),
  updateSuccess: createAction(TAGS.UPDATE_SUCCESS),
  error: createAction(TAGS.ERROR),
};

const initialState = {
  tags: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.error = null;
        break;
      case TAGS.FETCH_ALL:
        draft.loading = true;
        break;
      case TAGS.RECEIVE_ALL:
        draft.tags = action.payload.map(t => new Tag(t));
        draft.loading = false;
        draft.error = null;
        break;
      case TAGS.FETCH:
        draft.loading = true;
        break;
      case TAGS.RECEIVE: {
        draft.loading = false;
        addItem(new Tag(action.payload), draft.tags);
        break;
      }
      case TAGS.REMOVE:
        draft.loading = true;
        break;
      case TAGS.REMOVE_SUCCESS:
        draft.loading = false;
        removeItem(action.payload.id, draft.tags);
        break;
      case TAGS.UPDATE:
        draft.loading = true;
        break;
      case TAGS.UPDATE_SUCCESS:
        draft.loading = false;
        addItem(new Tag(action.payload), draft.tags);
        break;
      case TAGS.ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  tags: state => state.tags.tags,
  loading: state => state.tags.loading,
  error: state => state.tags.error,
};

export function* fetchTags(action) {
  try {
    const tags = yield call(api.fetchTags, action.payload);
    yield put(actions.receiveAll(tags));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchTag(action) {
  try {
    const tag = yield call(api.fetchTag, action.payload);
    yield put(actions.receive(tag));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createTag(action) {
  try {
    const tag = yield call(api.createTag, action.payload);
    yield put(actions.receive(tag));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeTag(action) {
  try {
    yield call(api.removeTag, action.payload);
    yield put(actions.removeSuccess(action.payload));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateTag(action) {
  try {
    const tag = yield call(api.updateTag, action.payload);
    yield put(actions.updateSuccess(tag));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* tagSaga() {
  yield all([
    yield takeLatest(TAGS.FETCH_ALL, fetchTags),
    yield takeLatest(TAGS.FETCH, fetchTag),
    yield takeEvery(TAGS.CREATE, createTag),
    yield takeEvery(TAGS.REMOVE, removeTag),
    yield takeEvery(TAGS.UPDATE, updateTag),
  ]);
}
