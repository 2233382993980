/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import { actions as groupsActions } from '../groups';
import { actions as seriesesActions } from '../serieses';

import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

import Competition from '../../models/Competition';

const COMPETITIONS = {
  FETCH_ALL: 'COMPETITIONS/FETCH_ALL',
  RECEIVE_ALL: 'COMPETITIONS/RECEIVE_ALL',
  FETCH: 'COMPETITIONS/FETCH',
  RECEIVE: 'COMPETITIONS/RECEIVE',
  CREATE: 'COMPETITIONS/CREATE',
  CREATE_SUCCESS: 'COMPETITIONS/CREATE_SUCCESS',
  REMOVE: 'COMPETITIONS/REMOVE',
  REMOVE_SUCCESS: 'COMPETITIONS/REMOVE_SUCCESS',
  UPDATE: 'COMPETITIONS/UPDATE',
  UPDATE_SUCCESS: 'COMPETITIONS/UPDATE_SUCCESS',
  ANONYMIZE: 'COMPETITIONS/ANONYMIZE',
  ANONYMIZE_SUCCESS: 'COMPETITIONS/ANONYMIZE_SUCCESS',
  ANONYMIZE_SUCCESS_RESET: 'COMPETITIONS/ANONYMIZE_SUCCESS_RESET',
  FETCH_SCHOOLS: 'COMPETITIONS/FETCH_SCHOOLS',
  RECEIVE_SCHOOLS: 'COMPETITIONS/RECEIVE_SCHOOLS',
  SEND_MAIL: 'COMPETITIONS/SEND_MAIL',
  SEND_MAIL_SUCCESS: 'COMPETITIONS/SEND_MAIL_SUCCESS',
  ERROR: 'COMPETITIONS/ERROR',
};

export const actions = {
  fetchAll: createAction(COMPETITIONS.FETCH_ALL),
  receiveAll: createAction(COMPETITIONS.RECEIVE_ALL),
  fetch: createAction(COMPETITIONS.FETCH),
  receive: createAction(COMPETITIONS.RECEIVE),
  create: createAction(COMPETITIONS.CREATE),
  createSuccess: createAction(COMPETITIONS.CREATE_SUCCESS),
  remove: createAction(COMPETITIONS.REMOVE),
  removeSuccess: createAction(COMPETITIONS.REMOVE_SUCCESS),
  update: createAction(COMPETITIONS.UPDATE),
  updateSuccess: createAction(COMPETITIONS.UPDATE_SUCCESS),
  anonymize: createAction(COMPETITIONS.ANONYMIZE),
  anonymizeSuccess: createAction(COMPETITIONS.ANONYMIZE_SUCCESS),
  anonymizeSuccessReset: createAction(COMPETITIONS.ANONYMIZE_SUCCESS_RESET),
  fetchSchools: createAction(COMPETITIONS.FETCH_SCHOOLS),
  receiveSchools: createAction(COMPETITIONS.RECEIVE_SCHOOLS),
  sendMail: createAction(COMPETITIONS.SEND_MAIL),
  sendMailSuccess: createAction(COMPETITIONS.SEND_MAIL_SUCCESS),
  error: createAction(COMPETITIONS.ERROR),
};

const initialState = {
  competitions: [],
  schools: [],
  loading: false,
  anonymizeLoading: false,
  anonymizeSuccess: false,
  sendMailSuccess: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.error = null;
        draft.anonymizeSuccess = false;
        draft.sendMailSuccess = false;
        break;
      case COMPETITIONS.FETCH_ALL:
        draft.loading = true;
        break;
      case COMPETITIONS.RECEIVE_ALL:
        draft.competitions = action.payload.map(c => new Competition(c));
        draft.loading = false;
        draft.error = null;
        break;
      case COMPETITIONS.FETCH:
        draft.loading = true;
        break;
      case COMPETITIONS.RECEIVE:
        draft.loading = false;
        addItem(new Competition(action.payload), draft.competitions);
        break;
      case COMPETITIONS.REMOVE:
        draft.loading = true;
        break;
      case COMPETITIONS.REMOVE_SUCCESS:
        draft.loading = false;
        removeItem(action.payload, draft.competitions);
        break;
      case COMPETITIONS.UPDATE:
        draft.loading = true;
        break;
      case COMPETITIONS.UPDATE_SUCCESS:
        draft.loading = false;
        addItem(new Competition(action.payload), draft.competitions);
        break;
      case COMPETITIONS.ANONYMIZE:
        draft.loading = true;
        draft.anonymizeLoading = true;
        draft.anonymizeSuccess = false;
        break;
      case COMPETITIONS.ANONYMIZE_SUCCESS:
        draft.loading = false;
        draft.anonymizeLoading = false;
        draft.anonymizeSuccess = true;
        addItem(new Competition(action.payload), draft.competitions);
        break;
      case COMPETITIONS.ANONYMIZE_SUCCESS_RESET:
        draft.anonymizeSuccess = false;
        break;
      case COMPETITIONS.FETCH_SCHOOLS:
        draft.schools = [];
        draft.loading = true;
        break;
      case COMPETITIONS.RECEIVE_SCHOOLS:
        draft.schools = action.payload;
        draft.loading = false;
        draft.error = null;
        break;
      case COMPETITIONS.SEND_MAIL:
        draft.loading = true;
        draft.sendMailSuccess = false;
        break;
      case COMPETITIONS.SEND_MAIL_SUCCESS:
        draft.loading = false;
        draft.sendMailSuccess = true;
        break;
      case COMPETITIONS.ERROR:
        draft.loading = false;
        draft.anonymizeLoading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  competitions: state => state.competitions.competitions,
  schools: state => state.competitions.schools,
  loading: state => state.competitions.loading,
  anonymizeLoading: state => state.competitions.anonymizeLoading,
  anonymizeSuccess: state => state.competitions.anonymizeSuccess,
  sendMailSuccess: state => state.competitions.sendMailSuccess,
  error: state => state.competitions.error,
};

export function* fetchCompetitions(action) {
  const payload = action.payload || {};
  payload.name = payload.name || '';

  try {
    const competitions = yield call(api.fetchCompetitions, payload);

    const { include } = payload;
    if (include) {
      const includes = include.split(',');
      if (includes.includes('groups')) {
        const groups = [].concat(...competitions.map(c => c.groups));
        yield put(groupsActions.receive(groups));
      }

      if (includes.includes('serieses')) {
        const serieses = [].concat(...competitions.map(c => c.serieses));
        yield put(seriesesActions.receive(serieses));
      }
    }

    yield put(actions.receiveAll(competitions));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchCompetition(action) {
  try {
    const competition = yield call(api.fetchCompetition, action.payload);
    yield put(actions.receive(competition));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createCompetition(action) {
  try {
    const competition = yield call(api.createCompetition, action.payload);
    yield put(actions.receive(competition));
    yield put(replace(`/competitions/${competition.id}`));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeCompetition(action) {
  try {
    yield call(api.removeCompetition, action.payload);
    yield put(actions.removeSuccess(action.payload));
    yield put(push('/competitions'));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateCompetition(action) {
  try {
    const competitions = yield select(selectors.competitions);
    const competition = competitions.find(u => u.id === action.payload.id);
    const updateData = {
      ...competition,
      ...action.payload,
    };

    const updatedCompetition = yield call(
      api.updateCompetition,
      competition.id,
      updateData
    );
    yield put(actions.updateSuccess(updatedCompetition));
    yield put(replace(`/competitions/${competition.id}`));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* anonymizeCompetition(action) {
  try {
    const competitions = yield select(selectors.competitions);
    const competition = competitions.find(u => u.id === action.payload.id);
    const updateData = {
      ...competition,
      is_anonymized: true,
    };

    const updatedCompetition = yield call(
      api.updateCompetition,
      competition.id,
      updateData
    );

    yield put(actions.anonymizeSuccess(updatedCompetition));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchCompetitionSchools(action) {
  try {
    const schools = yield call(api.fetchCompetitionSchools, action.payload);
    yield put(actions.receiveSchools(schools));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* sendMail(action) {
  try {
    yield call(api.sendMail, action.payload);
    yield put(actions.sendMailSuccess());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* competitionSaga() {
  yield all([
    yield takeLatest(COMPETITIONS.FETCH_ALL, fetchCompetitions),
    yield takeLatest(COMPETITIONS.FETCH, fetchCompetition),
    yield takeEvery(COMPETITIONS.CREATE, createCompetition),
    yield takeEvery(COMPETITIONS.REMOVE, removeCompetition),
    yield takeEvery(COMPETITIONS.UPDATE, updateCompetition),
    yield takeEvery(COMPETITIONS.ANONYMIZE, anonymizeCompetition),
    yield takeLatest(COMPETITIONS.FETCH_SCHOOLS, fetchCompetitionSchools),
    yield takeEvery(COMPETITIONS.SEND_MAIL, sendMail),
  ]);
}
