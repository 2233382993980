/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

import Pagination from '../../models/Pagination';

const FILES = {
  FETCH_ALL: 'FILES/FETCH_ALL',
  RECEIVE_ALL: 'FILES/RECEIVE_ALL',
  FETCH: 'FILES/FETCH',
  RECEIVE: 'FILES/RECEIVE',
  CREATE: 'FILES/CREATE',
  CREATE_SUCCESS: 'FILES/CREATE_SUCCESS',
  REMOVE: 'FILES/REMOVE',
  REMOVE_SUCCESS: 'FILES/REMOVE_SUCCESS',
  UPDATE: 'FILES/UPDATE',
  UPDATE_SUCCESS: 'FILES/UPDATE_SUCCESS',
  ERROR: 'FILES/ERROR',
};

export const actions = {
  fetchAll: createAction(FILES.FETCH_ALL),
  receiveAll: createAction(FILES.RECEIVE_ALL),
  fetch: createAction(FILES.FETCH),
  receive: createAction(FILES.RECEIVE),
  create: createAction(FILES.CREATE),
  createSuccess: createAction(FILES.CREATE_SUCCESS),
  remove: createAction(FILES.REMOVE),
  removeSuccess: createAction(FILES.REMOVE_SUCCESS),
  update: createAction(FILES.UPDATE),
  updateSuccess: createAction(FILES.UPDATE_SUCCESS),
  error: createAction(FILES.ERROR),
};

const initialState = {
  files: [],
  pagination: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.error = null;
        break;
      case FILES.FETCH_ALL:
        draft.loading = true;
        break;
      case FILES.RECEIVE_ALL:
        draft.files = action.payload.data;
        draft.pagination = new Pagination(action.payload);
        draft.loading = false;
        draft.error = null;
        break;
      case FILES.FETCH:
        draft.loading = true;
        break;
      case FILES.RECEIVE:
        draft.loading = false;
        addItem(action.payload, draft.files);
        break;
      case FILES.CREATE:
        draft.loading = true;
        break;
      case FILES.CREATE_SUCCESS:
        draft.loading = false;
        addItem(action.payload, draft.files);
        break;
      case FILES.REMOVE:
        draft.loading = true;
        break;
      case FILES.REMOVE_SUCCESS:
        draft.loading = false;
        removeItem(action.payload.id, draft.files);
        break;
      case FILES.UPDATE:
        draft.loading = true;
        break;
      case FILES.UPDATE_SUCCESS:
        draft.loading = false;
        addItem(action.payload, draft.files);
        break;
      case FILES.ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  files: state => state.files.files,
  pagination: state => state.files.pagination,
  loading: state => state.files.loading,
  error: state => state.files.error,
};

export function* fetchFiles(action) {
  try {
    const result = yield call(api.fetchFiles, action.payload);
    yield put(actions.receiveAll(result));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchFile(action) {
  try {
    const file = yield call(api.fetchFile, action.payload);
    yield put(actions.receive(file));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createFile(action) {
  try {
    const file = yield call(api.createFile, action.payload);
    yield put(actions.createSuccess(file));

    const { competitionId, disableRedirect } = action.payload;
    if (!disableRedirect) {
      yield put(
        replace(
          competitionId
            ? `/competitions/${competitionId}/files/${file.id}`
            : `/files/${file.id}`
        )
      );
    }
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeFile(action) {
  try {
    yield call(api.removeFile, action.payload);
    yield put(actions.removeSuccess(action.payload));

    const { competitionId, disableRedirect } = action.payload;
    if (!disableRedirect) {
      yield put(
        replace(competitionId ? `/competitions/${competitionId}` : '/files')
      );
    }
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateFile(action) {
  try {
    const files = yield select(selectors.files);
    const file = files.find(s => s.id === action.payload.id);

    const updatedFile = yield call(api.updateFile, {
      ...file,
      ...action.payload,
    });

    yield put(actions.updateSuccess(updatedFile));

    const { competitionId, disableRedirect } = action.payload;
    if (!disableRedirect) {
      yield put(
        replace(
          competitionId
            ? `/competitions/${competitionId}/files/${file.id}`
            : `/files/${file.id}`
        )
      );
    }
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fileSaga() {
  yield all([
    yield takeLatest(FILES.FETCH_ALL, fetchFiles),
    yield takeLatest(FILES.FETCH, fetchFile),
    yield takeEvery(FILES.CREATE, createFile),
    yield takeEvery(FILES.REMOVE, removeFile),
    yield takeEvery(FILES.UPDATE, updateFile),
  ]);
}
