/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { actions as userActions, selectors as userSelectors } from '../user';
import { actions as usersActions } from '../users';

import School from '../../models/School';
import Pagination from '../../models/Pagination';
import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

import { SCHOOLS, actions } from './actions';

export { actions };

const initialState = {
  schools: [],
  pagination: null,
  fuzzySchools: [],
  stats: {},
  loading: false,
  statsLoading: false,
  createSuccess: false,
  createdSchool: null,
  addUserSuccess: false,
  removeUserSuccess: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.createSuccess = false;
        draft.createdSchool = null;
        draft.addUserSuccess = false;
        draft.removeUserSuccess = false;
        draft.error = null;
        break;
      case SCHOOLS.FETCH_ALL:
        draft.loading = true;
        break;
      case SCHOOLS.RECEIVE_ALL:
        draft.schools = action.payload.data.map(s => new School(s));
        draft.pagination = new Pagination(action.payload);
        draft.loading = false;
        draft.error = null;
        break;
      case SCHOOLS.RECEIVE_FUZZY:
        draft.fuzzySchools = action.payload.map(s => new School(s));
        draft.error = null;
        break;
      case SCHOOLS.CLEAR_FUZZY:
        draft.fuzzySchools = [];
        break;
      case SCHOOLS.FETCH:
        draft.loading = true;
        break;
      case SCHOOLS.RECEIVE:
        draft.loading = false;
        addItem(new School(action.payload), draft.schools);
        break;
      case SCHOOLS.FETCH_STATS:
        draft.statsLoading = true;
        break;
      case SCHOOLS.RECEIVE_STATS:
        draft.stats[action.payload.school_id] = action.payload;
        draft.statsLoading = false;
        draft.error = null;
        break;
      case SCHOOLS.CREATE:
        draft.loading = true;
        draft.createSuccess = false;
        break;
      case SCHOOLS.CREATE_SUCCESS:
        draft.loading = false;
        draft.createSuccess = true;
        draft.createdSchool = new School(action.payload);
        addItem(new School(action.payload), draft.schools);
        break;
      case SCHOOLS.REMOVE:
        draft.loading = true;
        break;
      case SCHOOLS.REMOVE_SUCCESS:
        draft.loading = false;
        removeItem(action.payload.id, draft.schools);
        break;
      case SCHOOLS.UPDATE:
        draft.loading = true;
        break;
      case SCHOOLS.UPDATE_SUCCESS:
        draft.loading = false;
        addItem(new School(action.payload), draft.schools);
        break;
      case SCHOOLS.ADD_USER:
        draft.loading = true;
        draft.addUserSuccess = false;
        break;
      case SCHOOLS.ADD_USER_SUCCESS: {
        draft.loading = false;
        draft.addUserSuccess = true;

        const school = draft.schools.find(s => s.id === action.payload.id);
        if (!school) break;

        school.users.push(action.payload.user);
        break;
      }
      case SCHOOLS.REMOVE_USER:
        draft.loading = true;
        draft.removeUserSuccess = false;
        break;
      case SCHOOLS.REMOVE_USER_SUCCESS: {
        draft.loading = false;
        draft.removeUserSuccess = true;

        const school = draft.schools.find(s => s.id === action.payload.id);
        if (!school) break;

        const index = school.users.findIndex(
          u => u.id === action.payload.user.id
        );

        if (index === -1) break;

        school.users.splice(index, 1);
        break;
      }
      case SCHOOLS.ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  schools: state => state.schools.schools,
  pagination: state => state.schools.pagination,
  fuzzySchools: state => state.schools.fuzzySchools,
  stats: state => state.schools.stats,
  loading: state => state.schools.loading,
  statsLoading: state => state.schools.statsLoading,
  createSuccess: state => state.schools.createSuccess,
  createdSchool: state => state.schools.createdSchool,
  addUserSuccess: state => state.schools.addUserSuccess,
  removeUserSuccess: state => state.schools.removeUserSuccess,
  error: state => state.schools.error,
};

export function* fetchSchools(action) {
  const payload = { ...action.payload };

  try {
    const result = yield call(api.fetchSchools, payload);
    yield put(actions.receiveAll(result));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchFuzzySchools(action) {
  const payload = { ...action.payload, fuzzy: true };

  try {
    const schools = yield call(api.fetchSchools, payload);
    yield put(actions.receiveFuzzy(schools));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchSchool(action) {
  try {
    const school = yield call(api.fetchSchool, action.payload);
    yield put(actions.receive(school));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchSchoolStats(action) {
  try {
    const stats = yield call(api.fetchSchoolStats, action.payload);
    yield put(actions.receiveStats(stats));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createSchool(action) {
  try {
    const school = yield call(api.createSchool, action.payload);
    yield put(actions.createSuccess(school));

    if (!action.payload.silent) yield put(replace(`/schools/${school.id}`));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeSchool(action) {
  try {
    yield call(api.removeSchool, action.payload);
    yield put(actions.removeSuccess(action.payload));
    yield put(replace('/schools'));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateSchool(action) {
  try {
    const schools = yield select(selectors.schools);
    const school = schools.find(s => s.id === action.payload.id);

    const updatedSchool = yield call(api.updateSchool, {
      ...school,
      ...action.payload,
    });

    yield put(actions.updateSuccess(updatedSchool));
    yield put(replace(`/schools/${action.payload.id}`));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* addUserToSchool(action) {
  try {
    const user = yield call(api.addUserToSchool, action.payload);
    const currentUser = yield select(userSelectors.currentUser);

    yield put(actions.addUserSuccess({ id: action.payload.id, user }));
    yield put(usersActions.receive(user));

    if (user.id === currentUser.id) {
      yield put(userActions.fetch());
    }
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeUserFromSchool(action) {
  try {
    const user = yield call(api.removeUserFromSchool, action.payload);
    const currentUser = yield select(userSelectors.currentUser);

    yield put(actions.removeUserSuccess({ id: action.payload.id, user }));
    yield put(usersActions.receive(user));

    if (user.id === currentUser.id) {
      yield put(userActions.fetch());
    }
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* schoolsSaga() {
  yield all([
    yield takeLatest(SCHOOLS.FETCH_ALL, fetchSchools),
    yield takeLatest(SCHOOLS.FETCH_FUZZY, fetchFuzzySchools),
    yield takeLatest(SCHOOLS.FETCH, fetchSchool),
    yield takeLatest(SCHOOLS.FETCH_STATS, fetchSchoolStats),
    yield takeEvery(SCHOOLS.CREATE, createSchool),
    yield takeEvery(SCHOOLS.REMOVE, removeSchool),
    yield takeEvery(SCHOOLS.UPDATE, updateSchool),
    yield takeEvery(SCHOOLS.ADD_USER, addUserToSchool),
    yield takeEvery(SCHOOLS.REMOVE_USER, removeUserFromSchool),
  ]);
}
