import api from '../api';

export function fetchGroups({ competitionId, seriesId, name }) {
  return api.get(`/competitions/${competitionId}/series/${seriesId}/groups`, {
    params: {
      name,
    },
  });
}

export function fetchGroup({ competitionId, seriesId, id }) {
  return api.get(
    `/competitions/${competitionId}/series/${seriesId}/groups/${id}`
  );
}

export function fetchGroupStats({ competitionId, seriesId, id }) {
  return api.get(
    `/competitions/${competitionId}/series/${seriesId}/groups/${id}/stats`
  );
}

export function createGroup({ competitionId, seriesId, ...data }) {
  return api.post(`/competitions/${competitionId}/series/${seriesId}/groups`, {
    school_id: data.schoolId,
    name: data.name,
    count: data.count,
  });
}

export function removeGroup({ competitionId, seriesId, id }) {
  return api.delete(
    `/competitions/${competitionId}/series/${seriesId}/groups/${id}`
  );
}

export function updateGroup({ competitionId, seriesId, id, ...updateData }) {
  return api.put(
    `/competitions/${competitionId}/series/${seriesId}/groups/${id}`,
    {
      name: updateData.name,
      count: updateData.count,
    }
  );
}

export function updateStudent({
  competitionId,
  seriesId,
  groupId,
  id,
  ...updateData
}) {
  return api.put(
    `/competitions/${competitionId}/series/${seriesId}/groups/${groupId}/students/${id}`,
    {
      invalid: updateData.invalid,
    }
  );
}
