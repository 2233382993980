export default class Competition {
  constructor(data) {
    Object.keys(data).forEach(k => {
      this[k] = data[k];
    });
  }

  logoURL() {
    return `/api/competitions/${this.id}/files/${this.logo}/content`;
  }
}
