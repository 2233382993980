import { all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import { eventChannel } from 'redux-saga';
import storage from 'store/dist/store.modern';

import { actions as userActions } from '../user/actions';

const MESSAGES = {
  SEND: 'MESSAGES/SEND',
};

export const actions = {
  send: createAction(MESSAGES.SEND),
};

export const MESSAGE_TYPES = {
  LOGOUT: 'LOGOUT',
};

const createMessageChannel = () => {
  return eventChannel(emit => {
    const handleEvent = event => {
      if (event.key !== 'message' || !event.newValue) return;
      emit(JSON.parse(event.newValue));
    };

    window.addEventListener('storage', handleEvent);

    return () => {
      window.removeEventListener('storage', handleEvent);
    };
  });
};

// eslint-disable-next-line require-yield
export function* sendMessage(action) {
  storage.set('message', JSON.stringify(action.payload));
  storage.remove('message');
}

export function* watchMessages() {
  const messageChannel = yield call(createMessageChannel);

  while (true) {
    const payload = yield take(messageChannel);

    switch (payload.type) {
      case MESSAGE_TYPES.LOGOUT:
        yield put(userActions.logout({ initial: false }));
        break;

      default:
        break;
    }
  }
}

export function* messageSaga() {
  yield all([
    yield fork(watchMessages),
    yield takeEvery(MESSAGES.SEND, sendMessage),
  ]);
}
