/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import uuidv4 from 'uuid/v4';

import {
  actions as choicesActions,
  selectors as choicesSelectors,
  createChoice,
  updateChoice,
  removeChoice,
} from '../choices';

import { actions as answersActions } from '../answers';

import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

import Question from '../../models/Question';
import Pagination from '../../models/Pagination';

const QUESTIONS = {
  FETCH_ALL: 'QUESTIONS/FETCH_ALL',
  RECEIVE_ALL: 'QUESTIONS/RECEIVE_ALL',
  FETCH: 'QUESTIONS/FETCH',
  RECEIVE: 'QUESTIONS/RECEIVE',
  CLEAR: 'QUESTIONS/CLEAR',
  CREATE: 'QUESTIONS/CREATE',
  CREATE_SUCCESS: 'QUESTIONS/CREATE_SUCCESS',
  CLONE: 'QUESTIONS/CLONE',
  CLONE_SUCCESS: 'QUESTIONS/CLONE_SUCCESS',
  REMOVE: 'QUESTIONS/REMOVE',
  REMOVE_SUCCESS: 'QUESTIONS/REMOVE_SUCCESS',
  UPDATE: 'QUESTIONS/UPDATE',
  UPDATE_SUCCESS: 'QUESTIONS/UPDATE_SUCCESS',
  ERROR: 'QUESTIONS/ERROR',
};

export const actions = {
  fetchAll: createAction(QUESTIONS.FETCH_ALL),
  receiveAll: createAction(QUESTIONS.RECEIVE_ALL),
  fetch: createAction(QUESTIONS.FETCH),
  receive: createAction(QUESTIONS.RECEIVE),
  clear: createAction(QUESTIONS.CLEAR),
  create: createAction(QUESTIONS.CREATE),
  createSuccess: createAction(QUESTIONS.CREATE_SUCCESS),
  clone: createAction(QUESTIONS.CLONE),
  cloneSuccess: createAction(QUESTIONS.CLONE_SUCCESS),
  remove: createAction(QUESTIONS.REMOVE),
  removeSuccess: createAction(QUESTIONS.REMOVE_SUCCESS),
  update: createAction(QUESTIONS.UPDATE),
  updateSuccess: createAction(QUESTIONS.UPDATE_SUCCESS),
  error: createAction(QUESTIONS.ERROR),
};

const initialState = {
  questions: [],
  pagination: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.error = null;
        break;
      case QUESTIONS.FETCH_ALL:
        draft.loading = true;
        break;
      case QUESTIONS.RECEIVE_ALL:
        draft.questions = action.payload.data.map(q => new Question(q));
        draft.pagination = new Pagination(action.payload);
        draft.loading = false;
        draft.error = null;
        break;
      case QUESTIONS.FETCH:
        draft.loading = true;
        break;
      case QUESTIONS.RECEIVE: {
        draft.loading = false;

        const questions = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];

        questions
          .map(q => new Question(q))
          .forEach(q => addItem(q, draft.questions));

        break;
      }
      case QUESTIONS.CLEAR:
        draft.questions = [];
        break;
      case QUESTIONS.CLONE:
        draft.loading = true;
        break;
      case QUESTIONS.CLONE_SUCCESS:
        draft.loading = false;
        break;
      case QUESTIONS.REMOVE:
        draft.loading = true;
        break;
      case QUESTIONS.REMOVE_SUCCESS:
        draft.loading = false;
        removeItem(action.payload.id, draft.questions);
        break;
      case QUESTIONS.UPDATE:
        draft.loading = true;
        break;
      case QUESTIONS.UPDATE_SUCCESS:
        draft.loading = false;
        addItem(new Question(action.payload), draft.questions);
        break;
      case QUESTIONS.ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  questions: state => state.questions.questions,
  pagination: state => state.questions.pagination,
  loading: state => state.questions.loading,
  error: state => state.questions.error,
};

export function* fetchQuestions(action) {
  try {
    const result = yield call(api.fetchQuestions, action.payload);
    const { data: questions } = result;

    const { include } = action.payload;
    if (include) {
      const includes = include.split(',');
      if (includes.includes('choices')) {
        const choices = [].concat(...questions.map(q => q.choices));
        yield put(choicesActions.receive(choices));
      }

      if (includes.includes('answers')) {
        const answers = [].concat(...questions.map(q => q.answers));
        yield put(answersActions.receive(answers));
      }
    }

    yield put(actions.receiveAll(result));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchQuestion(action) {
  try {
    const question = yield call(api.fetchQuestion, action.payload);
    yield put(actions.receive(question));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createQuestion(action) {
  try {
    const { competitionId, seriesId, choices } = action.payload;

    let question = yield call(api.createQuestion, {
      ...action.payload,
      correct_choice: null,
      choice_ids: choices.map(c => c.id),
    });

    if (choices) {
      yield all(
        choices.map(c =>
          call(
            createChoice,
            choicesActions.create({
              ...c,
              competitionId,
              seriesId,
              questionId: question.id,
            })
          )
        )
      );
    }

    if (action.payload.correct_choice) {
      question = yield call(api.updateQuestion, {
        ...question,
        competitionId,
        seriesId,
        correct_choice: action.payload.correct_choice,
      });
    }

    yield put(actions.receive(question));

    yield put(
      replace(
        `/competitions/${competitionId}/series/${seriesId}/questions/${question.id}`
      )
    );
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* cloneQuestion(action) {
  try {
    const {
      competitionId,
      seriesId,
      series_id: originalSeriesId,
      id: originalId,
    } = action.payload;

    const originalQuestion = yield call(api.fetchQuestion, {
      competitionId,
      seriesId: originalSeriesId,
      id: originalId,
      include: 'choices',
    });

    const choices = originalQuestion.choices.map(c => ({
      ...c,
      id: uuidv4(),
    }));

    let question = yield call(api.createQuestion, {
      competitionId,
      seriesId,
      ...originalQuestion,
      series_id: seriesId,
      correct_choice: null,
      choice_ids: choices.map(c => c.id),
    });

    yield all(
      choices.map(c =>
        call(
          createChoice,
          choicesActions.create({
            competitionId,
            seriesId,
            questionId: question.id,
            ...c,
            question_id: question.id,
          })
        )
      )
    );

    if (originalQuestion.correct_choice) {
      const index = originalQuestion.choices.findIndex(
        c => c.id === originalQuestion.correct_choice
      );

      question = yield call(api.updateQuestion, {
        competitionId,
        seriesId,
        ...question,
        correct_choice: choices[index].id,
      });
    }

    yield put(actions.receive(question));

    yield put(actions.cloneSuccess());
    yield put(
      replace(
        `/competitions/${competitionId}/series/${seriesId}/questions/${question.id}`
      )
    );
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeQuestion(action) {
  try {
    yield call(api.removeQuestion, action.payload);
    yield put(actions.removeSuccess(action.payload));

    const { competitionId, seriesId } = action.payload;
    yield put(replace(`/competitions/${competitionId}/series/${seriesId}`));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateQuestion(action) {
  try {
    const {
      competitionId,
      seriesId,
      id: questionId,
      choices: newChoices,
    } = action.payload;

    if (newChoices) {
      const allChoices = yield select(choicesSelectors.choices);
      const choices = allChoices.filter(
        c => c.question_id === action.payload.id
      );

      const additions = [];
      const updates = [];
      const deletions = [];

      choices.forEach(choice => {
        const other = newChoices.find(c => c.id === choice.id);
        if (other) return;

        // Deleted item
        deletions.push({
          func: removeChoice,
          action: choicesActions.remove({
            competitionId,
            seriesId,
            questionId,
            id: choice.id,
          }),
        });
      });

      newChoices.forEach(choice => {
        const other = choices.find(c => c.id === choice.id);
        if (other) {
          // Compare to see if it was updated
          if (
            choice.description.fi !== other.description.fi ||
            choice.description.sv !== other.description.sv ||
            choice.description.en !== other.description.en
          ) {
            updates.push({
              func: updateChoice,
              action: choicesActions.update({
                ...choice,
                competitionId,
                seriesId,
                questionId,
              }),
            });
          }

          return;
        }

        // Added item
        additions.push({
          func: createChoice,
          action: choicesActions.create({
            ...choice,
            competitionId,
            seriesId,
            questionId,
          }),
        });
      });

      yield all(
        [...additions, ...updates, ...deletions].map(a =>
          call(a.func, a.action)
        )
      );
    }

    const questions = yield select(selectors.questions);
    const question = questions.find(u => u.id === questionId);
    const updateData = {
      ...question,
      ...action.payload,
      choice_ids: action.payload.choices.map(c => c.id),
    };

    const updatedQuestion = yield call(api.updateQuestion, updateData);
    yield put(actions.updateSuccess(updatedQuestion));

    yield put(
      replace(
        `/competitions/${competitionId}/series/${seriesId}/questions/${questionId}`
      )
    );
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* questionSaga() {
  yield all([
    yield takeLatest(QUESTIONS.FETCH_ALL, fetchQuestions),
    yield takeLatest(QUESTIONS.FETCH, fetchQuestion),
    yield takeEvery(QUESTIONS.CREATE, createQuestion),
    yield takeEvery(QUESTIONS.CLONE, cloneQuestion),
    yield takeEvery(QUESTIONS.REMOVE, removeQuestion),
    yield takeEvery(QUESTIONS.UPDATE, updateQuestion),
  ]);
}
