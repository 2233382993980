import { LOCALE_FALLBACK } from '../features/common/enums';

export default class Question {
  constructor(data) {
    Object.keys(data).forEach(k => {
      this[k] = data[k];
    });
  }

  needsFallback(locale) {
    return !this.description[locale];
  }

  descriptionWithFallback(locale) {
    if (this.description[locale]) {
      return this.description[locale];
    }

    const fallback = LOCALE_FALLBACK.find(l => this.description[l]);
    return this.description[fallback] || '';
  }
}
