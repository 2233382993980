import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import config from '../../config';

class ErrorBoundary extends Component {
  static propTypes = {
    error: PropTypes.any,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error } = this.props;

    return (
      <div className="container py-5 text-center">
        <div className="row justify-content-center">
          <div className="col-3">
            <img src="/assets/images/kangaroo.svg" alt="Kangaroo" />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            <h3 className="mb-0">
              <FormattedMessage
                id="errorboundary-header"
                defaultMessage="Virhetilanne"
              />
            </h3>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-8 offset-2">
            <h5>
              <FormattedMessage
                id="errorboundary-section-1"
                defaultMessage="Sovelluksessa tapahtui odottamaton virhe. Päivitä sivu ja yritä uudelleen."
              />
            </h5>

            <h5>
              <FormattedMessage
                id="errorboundary-section-2"
                defaultMessage="Mikäli ongelma toistuu, {contact}."
                values={{
                  contact: (
                    <a href={`mailto:${config.SUPPORT_EMAIL}`}>
                      <FormattedMessage
                        id="errorboundary-contact"
                        defaultMessage="ota yhteyttä ylläpitoon"
                      />
                    </a>
                  ),
                }}
              />
            </h5>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col small text-muted">{error && error.message}</div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;
