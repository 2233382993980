/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import storage from 'store/dist/store.modern';

import { PRACTICE_STUDENT_ID } from '../../features/common/enums';

import { COMMON } from '../common';
import { selectors as userSelectors } from '../user';

import * as api from './api';

const ANSWERS = {
  FETCH_ALL: 'ANSWERS/FETCH_ALL',
  RECEIVE_ALL: 'ANSWERS/RECEIVE_ALL',
  FETCH_SAVED: 'ANSWERS/FETCH_SAVED',
  RECEIVE: 'ANSWERS/RECEIVE',
  SET: 'ANSWERS/SET',
  SET_SUCCESS: 'ANSWERS/SET_SUCCESS',
  SET_ERROR: 'ANSWERS/SET_ERROR',
  CLEAR: 'ANSWERS/CLEAR',
  ERROR: 'ANSWERS/ERROR',
};

export const actions = {
  fetchAll: createAction(ANSWERS.FETCH_ALL),
  receiveAll: createAction(ANSWERS.RECEIVE_ALL),
  fetchSaved: createAction(ANSWERS.FETCH_SAVED),
  receive: createAction(ANSWERS.RECEIVE),
  set: createAction(ANSWERS.SET),
  setSuccess: createAction(ANSWERS.SET_SUCCESS),
  clear: createAction(ANSWERS.CLEAR),
  error: createAction(ANSWERS.ERROR),
};

const addAnswer = (answer, answers) => {
  const index = answers.findIndex(
    a =>
      a.question_id === answer.question_id && a.student_id === answer.student_id
  );

  // eslint-disable-next-line no-param-reassign
  if (index !== -1) answers[index] = answer;
  else answers.push(answer);
};

const removeAnswer = (answer, answers) => {
  const index = answers.findIndex(
    a =>
      a.question_id === answer.question_id && a.student_id === answer.student_id
  );

  if (index !== -1) answers.splice(index, 1);
};

const initialState = {
  answers: [],
  answersSeriesId: null,
  loading: false,
  setLoading: false,
  setSuccess: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.error = null;
        break;
      case ANSWERS.FETCH_ALL:
        draft.loading = true;
        break;
      case ANSWERS.RECEIVE_ALL:
        draft.answers = action.payload;
        draft.loading = false;
        draft.error = null;
        break;
      case ANSWERS.FETCH_SAVED: {
        const payload = storage.get('answers');
        if (!payload) break;

        const { seriesId, answers } = payload;
        draft.answers = answers;
        draft.answersSeriesId = seriesId;
        break;
      }
      case ANSWERS.RECEIVE: {
        draft.loading = false;
        draft.setLoading = false;

        const answers = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];

        answers.forEach(answer => {
          const func =
            answer.choice_id !== null || answer.invalid
              ? addAnswer
              : removeAnswer;

          func(answer, draft.answers);
        });

        break;
      }
      case ANSWERS.SET:
        draft.loading = true;
        draft.setLoading = true;
        draft.setSuccess = false;
        break;
      case ANSWERS.SET_SUCCESS:
        draft.loading = false;
        draft.setLoading = false;
        draft.setSuccess = true;
        break;
      case ANSWERS.ERROR:
        draft.loading = false;
        draft.setLoading = false;
        draft.error = action.payload;
        break;
      case ANSWERS.CLEAR:
        draft.answers = [];
        draft.answersSeriesId = null;
        storage.remove('answers');
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  answers: state => state.answers.answers,
  answersSeriesId: state => state.answers.answersSeriesId,
  loading: state => state.answers.loading,
  setLoading: state => state.answers.setLoading,
  setSuccess: state => state.answers.setSuccess,
  error: state => state.answers.error,
};

export function* fetchAnswers(action) {
  try {
    const answers = yield call(api.fetchAnswers, action.payload);
    yield put(actions.receiveAll(answers));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* setAnswer(action) {
  try {
    let { studentId } = action.payload;

    // If we did not receive a student ID, we are a student
    if (!studentId) {
      const currentUser = yield select(userSelectors.currentUser);
      ({ id: studentId } = currentUser);
    }

    const answers = yield select(selectors.answers);

    const originalAnswer = answers.find(
      a =>
        a.question_id === action.payload.questionId &&
        a.student_id === studentId
    ) || {
      choice_id: null,
      question_id: action.payload.questionId,
      student_id: studentId,
      invalid: false,
    };

    const optimisticAnswer = {
      choice_id: action.payload.choiceId,
      question_id: action.payload.questionId,
      student_id: studentId,
      invalid: action.payload.invalid,
    };

    // Optimistic update to give instant feedback
    yield put(actions.receive(optimisticAnswer));

    // Practice user, do not save the answer
    if (studentId === PRACTICE_STUDENT_ID) {
      const newAnswers = yield select(selectors.answers);
      storage.set('answers', {
        seriesId: action.payload.seriesId,
        answers: newAnswers,
      });

      return;
    }

    try {
      const answer = yield call(api.setAnswer, {
        ...action.payload,
        studentId,
      });

      // Update with actual data
      yield put(actions.receive(answer));
      yield put(actions.setSuccess());
    } catch (error) {
      // Undo optimistic update
      yield put(actions.receive(originalAnswer));
      throw error;
    }
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* answerSaga() {
  yield all([
    yield takeLatest(ANSWERS.FETCH_ALL, fetchAnswers),
    yield takeEvery(ANSWERS.SET, setAnswer),
  ]);
}
