/* eslint-disable no-param-reassign */
import { createAction } from 'redux-actions';
import produce from 'immer';
import storage from 'store/dist/store.modern';

const LOCALE = {
  SET: 'LOCALE/SET',
  SET_QUESTION: 'LOCALE/SET_QUESTION',
};

export const actions = {
  set: createAction(LOCALE.SET),
  setQuestion: createAction(LOCALE.SET_QUESTION),
};

const initialState = {
  locale: storage.get('locale') || 'fi',
  questionLocale: storage.get('question_locale'),
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOCALE.SET:
        draft.locale = action.payload;
        storage.set('locale', action.payload);
        break;
      case LOCALE.SET_QUESTION:
        draft.questionLocale = action.payload;
        storage.set('question_locale', action.payload);
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  locale: state => state.locale.locale,
  questionLocale: state => state.locale.questionLocale || state.locale.locale,
};
