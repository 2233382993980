/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

const CONTENTS = {
  FETCH_ALL: 'CONTENTS/FETCH_ALL',
  RECEIVE_ALL: 'CONTENTS/RECEIVE_ALL',
  FETCH: 'CONTENTS/FETCH',
  RECEIVE: 'CONTENTS/RECEIVE',
  CREATE: 'CONTENTS/CREATE',
  CREATE_SUCCESS: 'CONTENTS/CREATE_SUCCESS',
  REMOVE: 'CONTENTS/REMOVE',
  REMOVE_SUCCESS: 'CONTENTS/REMOVE_SUCCESS',
  UPDATE: 'CONTENTS/UPDATE',
  UPDATE_SUCCESS: 'CONTENTS/UPDATE_SUCCESS',
  UPDATE_ORDER: 'CONTENTS/UPDATE_ORDER',
  UPDATE_ORDER_SUCCESS: 'CONTENTS/UPDATE_ORDER_SUCCESS',
  ERROR: 'CONTENTS/ERROR',
};

export const actions = {
  fetchAll: createAction(CONTENTS.FETCH_ALL),
  receiveAll: createAction(CONTENTS.RECEIVE_ALL),
  fetch: createAction(CONTENTS.FETCH),
  receive: createAction(CONTENTS.RECEIVE),
  create: createAction(CONTENTS.CREATE),
  createSuccess: createAction(CONTENTS.CREATE_SUCCESS),
  remove: createAction(CONTENTS.REMOVE),
  removeSuccess: createAction(CONTENTS.REMOVE_SUCCESS),
  update: createAction(CONTENTS.UPDATE),
  updateSuccess: createAction(CONTENTS.UPDATE_SUCCESS),
  updateOrder: createAction(CONTENTS.UPDATE_ORDER),
  updateOrderSuccess: createAction(CONTENTS.UPDATE_ORDER_SUCCESS),
  error: createAction(CONTENTS.ERROR),
};

const initialState = {
  contents: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.error = null;
        break;
      case CONTENTS.FETCH_ALL:
        draft.loading = true;
        break;
      case CONTENTS.RECEIVE_ALL:
        draft.contents = action.payload;
        draft.loading = false;
        draft.error = null;
        break;
      case CONTENTS.FETCH:
        draft.loading = true;
        break;
      case CONTENTS.RECEIVE:
        draft.loading = false;
        addItem(action.payload, draft.contents);
        break;
      case CONTENTS.REMOVE:
        draft.loading = true;
        break;
      case CONTENTS.REMOVE_SUCCESS:
        draft.loading = false;
        removeItem(action.payload, draft.contents);
        break;
      case CONTENTS.UPDATE:
        draft.loading = true;
        break;
      case CONTENTS.UPDATE_SUCCESS:
        draft.loading = false;
        addItem(action.payload, draft.contents);
        break;
      case CONTENTS.UPDATE_ORDER:
        draft.loading = true;
        break;
      case CONTENTS.UPDATE_ORDER_SUCCESS:
        draft.loading = false;
        action.payload.forEach(c => addItem(c, draft.contents));
        break;
      case CONTENTS.ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  contents: state => state.contents.contents,
  loading: state => state.contents.loading,
  error: state => state.contents.error,
};

export function* fetchContents(action) {
  try {
    const contents = yield call(api.fetchContents, action.payload);
    yield put(actions.receiveAll(contents));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchContent(action) {
  try {
    const content = yield call(api.fetchContent, action.payload);
    yield put(actions.receive(content));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createContent(action) {
  try {
    const content = yield call(api.createContent, action.payload);
    yield put(actions.receive(content));
    yield put(replace(`/contents/${content.id}`));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeContent(action) {
  try {
    yield call(api.removeContent, action.payload);
    yield put(actions.removeSuccess(action.payload));
    yield put(push('/contents'));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateContent(action) {
  try {
    const contents = yield select(selectors.contents);
    const content = contents.find(c => c.id === action.payload.id);
    const updateData = {
      ...content,
      ...action.payload,
    };

    const updatedContent = yield call(
      api.updateContent,
      content.id,
      updateData
    );

    yield put(actions.updateSuccess(updatedContent));
    yield put(replace(`/contents/${content.id}`));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateContentOrder(action) {
  try {
    const contents = yield call(api.updateContentOrder, action.payload);
    yield put(actions.updateOrderSuccess(contents));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* contentSaga() {
  yield all([
    yield takeLatest(CONTENTS.FETCH_ALL, fetchContents),
    yield takeLatest(CONTENTS.FETCH, fetchContent),
    yield takeEvery(CONTENTS.CREATE, createContent),
    yield takeEvery(CONTENTS.REMOVE, removeContent),
    yield takeEvery(CONTENTS.UPDATE, updateContent),
    yield takeEvery(CONTENTS.UPDATE_ORDER, updateContentOrder),
  ]);
}
