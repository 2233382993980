/*
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import init from './init';
import locale from './locale';
import user from './user';
import users from './users';
import competitions from './competitions';
import serieses from './serieses';
import schools from './schools';
import groups from './groups';
import questions from './questions';
import choices from './choices';
import answers from './answers';
import files from './files';
import contents from './contents';
import tags from './tags';

export default history =>
  combineReducers({
    router: connectRouter(history),
    init,
    locale,
    user,
    users,
    competitions,
    serieses,
    schools,
    groups,
    questions,
    choices,
    answers,
    files,
    contents,
    tags,
  });
