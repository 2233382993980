/* eslint-disable import/prefer-default-export */
import api from '../api';

export function fetchCurrentUser() {
  return api.get('/users/me');
}

export function login(credentials) {
  return api.post('/auth/credentials', credentials);
}

export function loginCode(credentials) {
  return api.post('/auth/code', credentials);
}

export function loginStudent(credentials) {
  return api.post('/auth/student', credentials);
}

export function logout() {
  return api.post('/auth/logout');
}

export function changePassword(oldPassword, newPassword) {
  return api.put('/users/me', {
    old_password: oldPassword,
    password: newPassword,
  });
}

export function linkExternal({ provider, oldPassword }) {
  return api.post('/users/me/link-external', {
    provider,
    old_password: oldPassword,
  });
}

export function register({ username, password, school, external }) {
  return api.post('/auth/register', {
    username,
    password,
    school,
    external,
  });
}

export function resetPassword(data) {
  return api.post('/auth/password-reset', data);
}

export function removeSelf() {
  return api.delete('/users/me');
}
