import api from '../api';

export function fetchAnswers({ competitionId, seriesId, groupId }) {
  return api.get(
    `/competitions/${competitionId}/series/${seriesId}/groups/${groupId}/answers`
  );
}

export function setAnswer({
  competitionId,
  seriesId,
  questionId,
  choiceId,
  studentId,
  invalid,
}) {
  return api.post(
    `/competitions/${competitionId}/series/${seriesId}/questions/${questionId}/answers`,
    {
      choice_id: choiceId,
      student_id: studentId,
      invalid,
    }
  );
}
