/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';

import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

import Choice from '../../models/Choice';

const CHOICES = {
  FETCH_ALL: 'CHOICES/FETCH_ALL',
  RECEIVE_ALL: 'CHOICES/RECEIVE_ALL',
  FETCH: 'CHOICES/FETCH',
  RECEIVE: 'CHOICES/RECEIVE',
  CREATE: 'CHOICES/CREATE',
  CREATE_SUCCESS: 'CHOICES/CREATE_SUCCESS',
  REMOVE: 'CHOICES/REMOVE',
  REMOVE_SUCCESS: 'CHOICES/REMOVE_SUCCESS',
  UPDATE: 'CHOICES/UPDATE',
  UPDATE_SUCCESS: 'CHOICES/UPDATE_SUCCESS',
  ERROR: 'CHOICES/ERROR',
};

export const actions = {
  fetchAll: createAction(CHOICES.FETCH_ALL),
  receiveAll: createAction(CHOICES.RECEIVE_ALL),
  fetch: createAction(CHOICES.FETCH),
  receive: createAction(CHOICES.RECEIVE),
  create: createAction(CHOICES.CREATE),
  createSuccess: createAction(CHOICES.CREATE_SUCCESS),
  remove: createAction(CHOICES.REMOVE),
  removeSuccess: createAction(CHOICES.REMOVE_SUCCESS),
  update: createAction(CHOICES.UPDATE),
  updateSuccess: createAction(CHOICES.UPDATE_SUCCESS),
  error: createAction(CHOICES.ERROR),
};

const initialState = {
  choices: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.error = null;
        break;
      case CHOICES.FETCH_ALL:
        draft.loading = true;
        break;
      case CHOICES.RECEIVE_ALL:
        draft.choices = action.payload.map(c => new Choice(c));
        draft.loading = false;
        draft.error = null;
        break;
      case CHOICES.FETCH:
        draft.loading = true;
        break;
      case CHOICES.RECEIVE: {
        draft.loading = false;

        const choices = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];

        choices.forEach(choice => addItem(new Choice(choice), draft.choices));
        break;
      }
      case CHOICES.REMOVE:
        draft.loading = true;
        break;
      case CHOICES.REMOVE_SUCCESS:
        draft.loading = false;
        removeItem(action.payload.id, draft.choices);
        break;
      case CHOICES.UPDATE:
        draft.loading = true;
        break;
      case CHOICES.UPDATE_SUCCESS:
        draft.loading = false;
        addItem(new Choice(action.payload), draft.choices);
        break;
      case CHOICES.ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  choices: state => state.choices.choices,
  loading: state => state.choices.loading,
  error: state => state.choices.error,
};

export function* fetchChoices(action) {
  try {
    const choices = yield call(api.fetchChoices, action.payload);
    yield put(actions.receiveAll(choices));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchChoice(action) {
  try {
    const choice = yield call(api.fetchChoice, action.payload);
    yield put(actions.receive(choice));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createChoice(action) {
  try {
    const choice = yield call(api.createChoice, action.payload);
    yield put(actions.createSuccess(choice));
    yield put(actions.receive(choice));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeChoice(action) {
  try {
    yield call(api.removeChoice, action.payload);
    yield put(actions.removeSuccess(action.payload));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateChoice(action) {
  try {
    const choices = yield select(selectors.choices);
    const choice = choices.find(u => u.id === action.payload.id);
    const updateData = {
      ...choice,
      ...action.payload,
    };

    const updatedChoice = yield call(api.updateChoice, updateData);
    yield put(actions.updateSuccess(updatedChoice));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* choiceSaga() {
  yield all([
    yield takeLatest(CHOICES.FETCH_ALL, fetchChoices),
    yield takeLatest(CHOICES.FETCH, fetchChoice),
    yield takeEvery(CHOICES.CREATE, createChoice),
    yield takeEvery(CHOICES.REMOVE, removeChoice),
    yield takeEvery(CHOICES.UPDATE, updateChoice),
  ]);
}
