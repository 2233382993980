import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export default function withFetcher(
  WrappedComponent,
  fetch,
  fetcher,
  selector
) {
  const c = class Competition extends Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      fetch: PropTypes.func.isRequired,
      item: PropTypes.object,
    };

    static defaultProps = {
      item: null,
    };

    componentDidMount() {
      const { match, item } = this.props;
      if (item) return;

      fetcher(this.props.fetch, match.params);
    }

    render() {
      const { match, item } = this.props;
      if (!item) return null;

      return <WrappedComponent item={item} params={match.params} />;
    }
  };

  return connect(
    state => ({
      items: selector(state),
    }),
    dispatch =>
      bindActionCreators(
        {
          fetch,
        },
        dispatch
      ),
    (state, dispatch, props) => ({
      item:
        state.items && state.items.find(i => i.id === props.match.params.id),
      ...dispatch,
      ...props,
    })
  )(c);
}
