import PropTypes from 'prop-types';

export const APIErrorPropType = PropTypes.shape({
  status: PropTypes.number.isRequired,
  message: PropTypes.string,
});

export const LocalizationPropType = PropTypes.shape({
  fi: PropTypes.string.isRequired,
  sv: PropTypes.string.isRequired,
  en: PropTypes.string.isRequired,
});

export const UserPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  competition_permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  password: PropTypes.string,
});

const CompetitionBasePropType = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: LocalizationPropType.isRequired,
  logo: PropTypes.string,
  is_archived: PropTypes.bool.isRequired,
  web_public_at: PropTypes.string,
  pdf_public_at: PropTypes.string,
};

export const CompetitionPropType = PropTypes.shape({
  ...CompetitionBasePropType,
  id: PropTypes.string.isRequired,
});

export const CompetitionCreatePropType = PropTypes.shape(
  CompetitionBasePropType
);

const SeriesBasePropType = {
  name: PropTypes.string.isRequired,
  description: LocalizationPropType.isRequired,
  base_points: PropTypes.number.isRequired,
  time_limit: PropTypes.number,
  question_ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const SeriesPropType = PropTypes.shape({
  ...SeriesBasePropType,
  id: PropTypes.string.isRequired,
  competition_id: PropTypes.string.isRequired,
});

export const SeriesCreatePropType = PropTypes.shape(SeriesBasePropType);

export const StudentPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  group_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  started_at: PropTypes.string,
});

export const GroupPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  series_id: PropTypes.string.isRequired,
  school_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  students: PropTypes.arrayOf(StudentPropType).isRequired,
});

export const GroupCreatePropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
});

const QuestionBasePropType = {
  description: LocalizationPropType.isRequired,
  example: LocalizationPropType,
  correct_choice: PropTypes.string,
  correct_points: PropTypes.number.isRequired,
  incorrect_points: PropTypes.number.isRequired,
  skip_points: PropTypes.number.isRequired,
};

export const QuestionPropType = PropTypes.shape({
  ...QuestionBasePropType,
  id: PropTypes.string.isRequired,
  series_id: PropTypes.string.isRequired,
});

export const QuestionCreatePropType = PropTypes.shape(QuestionBasePropType);

const ChoiceBasePropType = {
  description: LocalizationPropType.isRequired,
};

export const ChoicePropType = PropTypes.shape({
  ...ChoiceBasePropType,
  id: PropTypes.string.isRequired,
  question_id: PropTypes.string.isRequired,
});

export const ChoiceCreatePropType = PropTypes.shape(ChoiceBasePropType);

export const AnswerPropType = PropTypes.shape({
  student_id: PropTypes.string.isRequired,
  question_id: PropTypes.string.isRequired,
  choice_id: PropTypes.string.isRequired,
});

const SchoolBasePropType = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  municipality: PropTypes.string.isRequired,
};

export const SchoolPropType = PropTypes.shape({
  ...SchoolBasePropType,
  id: PropTypes.string.isRequired,
});

export const FuzzySchoolPropType = PropTypes.shape({
  ...SchoolBasePropType,
  id: PropTypes.string.isRequired,
  similarity: PropTypes.number.isRequired,
});

export const SchoolCreatePropType = PropTypes.shape(SchoolBasePropType);

const FileBasePropType = {
  filename: PropTypes.string.isRequired,
};

export const FilePropType = PropTypes.shape({
  ...FileBasePropType,
  id: PropTypes.string.isRequired,
  competition_id: PropTypes.string,
  mime_type: PropTypes.string.isRequired,
  is_public: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
});

export const FileCreatePropType = PropTypes.shape(FileBasePropType);

export const PaginationPropType = PropTypes.shape({
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
});

const ContentBasePropType = {
  type: PropTypes.string.isRequired,
  body: LocalizationPropType.isRequired,
};

export const ContentPropType = PropTypes.shape({
  ...ContentBasePropType,
  id: PropTypes.string.isRequired,
});

export const ContentCreatePropType = PropTypes.shape(ContentBasePropType);

export const TagPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: LocalizationPropType.isRequired,
});

export const SeriesParticipantPropType = PropTypes.shape({
  placement: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  school: SchoolPropType,
  points: PropTypes.number.isRequired,
  streak: PropTypes.number.isRequired,
});
