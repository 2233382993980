import queryString from 'query-string';
import api from '../api';

export function fetchQuestions({ competitionId, seriesId, ...params }) {
  const url = competitionId
    ? `/competitions/${competitionId}/series/${seriesId}/questions`
    : '/questions';

  return api.get(url, {
    params,
    paramsSerializer: p => queryString.stringify(p),
  });
}

export function fetchQuestion({ competitionId, seriesId, id, include }) {
  const url = competitionId
    ? `/competitions/${competitionId}/series/${seriesId}/questions/${id}`
    : `/questions/${id}`;

  return api.get(url, {
    params: {
      include,
    },
  });
}

export function createQuestion({ competitionId, seriesId, ...data }) {
  return api.post(
    `/competitions/${competitionId}/series/${seriesId}/questions`,
    data
  );
}

export function removeQuestion({ competitionId, seriesId, id }) {
  return api.delete(
    `/competitions/${competitionId}/series/${seriesId}/questions/${id}`
  );
}

export function updateQuestion({ competitionId, seriesId, id, ...updateData }) {
  return api.put(
    `/competitions/${competitionId}/series/${seriesId}/questions/${id}`,
    updateData
  );
}
