import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import MathJax from 'react-mathjax2';

import * as Sentry from '@sentry/react';

import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
  faArchive,
  faArrowAltCircleUp,
  faChartBar,
  faCheck,
  faCheckCircle as fasFaCheckCircle,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle as fasFaCircle,
  faClone,
  faCode,
  faDownload,
  faEraser,
  faExclamationCircle,
  faFile,
  faFileAlt,
  faFileImage,
  faGlobe,
  faImage,
  faInfoCircle,
  faMinusCircle,
  faPaperPlane,
  faPencilAlt,
  faPlus,
  faPrint,
  faQuestionCircle,
  faSchool,
  faSearch,
  faStopwatch,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTrashRestore,
  faUserCheck,
  faUserCircle,
  faUserTimes,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendar,
  faCheckCircle as farFaCheckCircle,
  faCircle as farFaCircle,
  faClock,
  faEye,
  faEyeSlash,
  faStopCircle,
} from '@fortawesome/free-regular-svg-icons';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';

import 'react-dragula/dist/dragula.min.css';

import './assets/scss/bootstrap.scss';
import './assets/scss/now-ui-kit.scss';
import './assets/scss/kengurukone.scss';

import config from './config';
import HotApp from './hotApp';
import store, { history } from './store';

Sentry.init({
  dsn:
    config.SENTRY_PUBLIC_DSN_TABLE[document.location.hostname] ||
    config.SENTRY_PUBLIC_DSN_FALLBACK,
});

if (!Intl.PluralRules) {
  import('@formatjs/intl-pluralrules/polyfill');
  import('@formatjs/intl-pluralrules/dist/locale-data/fi');
  import('@formatjs/intl-pluralrules/dist/locale-data/en');
  import('@formatjs/intl-pluralrules/dist/locale-data/sv');
}

library.add(
  // fas
  faArchive,
  faArrowAltCircleUp,
  faChartBar,
  faCheck,
  fasFaCheckCircle,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  fasFaCircle,
  faClone,
  faCode,
  faDownload,
  faEraser,
  faExclamationCircle,
  faFile,
  faFileAlt,
  faFileImage,
  faGlobe,
  faImage,
  faInfoCircle,
  faMinusCircle,
  faPaperPlane,
  faPencilAlt,
  faPlus,
  faPrint,
  faQuestionCircle,
  faSchool,
  faSearch,
  faStopwatch,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTrashRestore,
  faUserCheck,
  faUserCircle,
  faUserTimes,

  // far
  faCalendar,
  farFaCheckCircle,
  farFaCircle,
  faClock,
  faEye,
  faEyeSlash,
  faStopCircle,

  // fab
  faYoutube
);

dom.watch();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MathJax.Context
        input="tex"
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_CHTML-full,/assets/js/config.fd9c4e96526aec15f31621de7e66bf10.js"
        options={{
          displayAlign: 'left',
          showMathMenu: false,
          showMathMenuMSIE: false,
          messageStyle: 'none',
          tex2jax: {
            inlineMath: [
              ['$', '$'],
              ['\\(', '\\)'],
            ],
          },
        }}
      >
        <HotApp />
      </MathJax.Context>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);
