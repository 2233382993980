import { LOCALE_FALLBACK } from '../features/common/enums';

export default class Tag {
  constructor(data) {
    Object.keys(data).forEach(k => {
      this[k] = data[k];
    });
  }

  localizedName(locale) {
    if (this.name[locale]) {
      return this.name[locale];
    }

    const fallback = LOCALE_FALLBACK.find(l => this.name[l]);
    return this.name[fallback] || '';
  }
}
