import api from '../api';

export function fetchFiles({ competitionId, ...params }) {
  const url = competitionId ? `/competitions/${competitionId}/files` : '/files';
  return api.get(url, { params });
}

export function fetchFile({ competitionId, id }) {
  const url = competitionId
    ? `/competitions/${competitionId}/files/${id}`
    : `/files/${id}`;

  return api.get(url);
}

export function createFile({ competitionId, ...data }) {
  const formData = new FormData();
  Object.keys(data).forEach(k => {
    if (Object.prototype.hasOwnProperty.call(data, k)) {
      formData.append(k, data[k]);
    }
  });

  const url = competitionId ? `/competitions/${competitionId}/files` : '/files';
  return api.post(url, formData);
}

export function removeFile({ competitionId, id }) {
  const url = competitionId
    ? `/competitions/${competitionId}/files/${id}`
    : `/files/${id}`;

  return api.delete(url);
}

export function updateFile({ competitionId, id, ...updateData }) {
  const formData = new FormData();
  Object.keys(updateData).forEach(k => {
    formData.append(k, updateData[k]);
  });

  const url = competitionId
    ? `/competitions/${competitionId}/files/${id}`
    : `/files/${id}`;

  return api.put(url, formData);
}
