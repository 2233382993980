import { createAction } from 'redux-actions';

export const addItem = (item, items) => {
  const index = items.findIndex(i => i.id === item.id);

  // eslint-disable-next-line no-param-reassign
  if (index !== -1) items[index] = item;
  else items.push(item);
};

export const removeItem = (itemId, items) => {
  const index = items.findIndex(i => i.id === itemId);
  if (index !== -1) items.splice(index, 1);
};

export const COMMON = {
  NAVIGATE: 'COMMON/NAVIGATE',
};

export const actions = {
  onNavigate: createAction(COMMON.NAVIGATE),
};
