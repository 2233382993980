import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { actions as localeActions } from '../../store/locale';

import { actions, selectors } from '../../store/user';

import { ROLES, ROLE_STUDENT } from '../common/enums';

class Navigation extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.object,
    activeSchoolId: PropTypes.string,
    setActiveSchoolId: PropTypes.func.isRequired,
    setLocale: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: null,
    activeSchoolId: null,
  };

  logout = e => {
    e.preventDefault();
    this.props.logout({ initial: true });
  };

  onSchoolChange = (e, school) => {
    if (e) {
      e.currentTarget.blur();
      e.preventDefault();
    }

    this.props.setActiveSchoolId(school.id);
  };

  onLocaleChange = (e, locale) => {
    if (e) {
      e.currentTarget.blur();
      e.preventDefault();
    }

    this.props.setLocale(locale);
  };

  renderNavItem = (route, link) => {
    const { location } = this.props;
    const currentRoute = location.pathname.split('/')[1];
    const active = currentRoute === route && location.pathname !== '/users/me';

    return (
      <li
        className={classNames('nav-item', {
          active,
        })}
      >
        <Link className="nav-link" to={`/${route}`}>
          {link}

          {active && (
            <span className="sr-only">
              <FormattedMessage
                id="navigation-current"
                defaultMessage="(nykyinen sivu)"
              />
            </span>
          )}
        </Link>
      </li>
    );
  };

  renderNavDropdownItem = (route, link) => {
    const { location } = this.props;
    const currentRoute = location.pathname.split('/')[1];
    const active = currentRoute === route && location.pathname !== '/users/me';

    return (
      <Link
        className={classNames('dropdown-item', { active })}
        to={`/${route}`}
      >
        {link}

        {active && (
          <span className="sr-only">
            <FormattedMessage
              id="navigation-current"
              defaultMessage="(nykyinen sivu)"
            />
          </span>
        )}
      </Link>
    );
  };

  renderSchools = () => {
    const { user, activeSchoolId } = this.props;

    const activeSchool = user.schools.find(s => s.id === activeSchoolId);
    if (!activeSchool) return null;

    if (user.schools.length === 1) {
      return (
        <li className="Navigation-school-selector nav-item">
          <div
            className="d-flex align-items-center nav-link"
            title={user.schools[0].displayName()}
          >
            <i className="fas fa-school fa-2x" />
            <span className="Navigation-school-name overflow-ellipsis ml-3">
              {user.schools[0].displayName()}
            </span>
          </div>
        </li>
      );
    }

    // Sort the current active school as first
    const selectSchools = [...user.schools].sort((a, b) => {
      if (a.id === activeSchool.id) return -1;
      if (b.id === activeSchool.id) return 1;
      return 0;
    });

    return (
      <li className="Navigation-school-selector nav-item dropdown">
        <a
          href="#"
          className="d-flex align-items-center nav-link dropdown-toggle text-nowrap"
          title={activeSchool.displayName()}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fas fa-school fa-2x mr-3" />
          <span className="Navigation-school-name overflow-ellipsis">
            {activeSchool.displayName()}
          </span>
        </a>

        <div className="dropdown-menu">
          {selectSchools.map(school => (
            <a
              key={school.id}
              className="dropdown-item"
              href="#"
              onClick={e => this.onSchoolChange(e, school)}
            >
              {school.displayName()}
            </a>
          ))}
        </div>
      </li>
    );
  };

  renderLocale = () => {
    const { locale: activeLocale } = this.props.intl;

    const locales = {
      fi: <FormattedMessage id="locale-fi" defaultMessage="Suomi" />,
      sv: <FormattedMessage id="locale-sv" defaultMessage="Svenska" />,
      en: <FormattedMessage id="locale-en" defaultMessage="English" />,
    };

    return (
      <li className="Navigation-locale-selector nav-item dropdown">
        <a
          href="#"
          className="nav-link dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            className="mr-3"
            src={`/assets/images/locales/${activeLocale}.png`}
            alt={activeLocale}
          />

          {locales[activeLocale]}
        </a>

        <div className="dropdown-menu">
          {Object.keys(locales).map(locale => (
            <a
              key={locale}
              className="dropdown-item"
              href="#"
              onClick={e => this.onLocaleChange(e, locale)}
            >
              {locales[locale]}
            </a>
          ))}
        </div>
      </li>
    );
  };

  render() {
    const { user, location } = this.props;

    const isAdmin = user && user.roles.some(role => role === ROLES.ADMIN);
    const isOrganizer =
      user && user.roles.some(role => role === ROLES.ORGANIZER);
    const isTeacher = user && user.roles.some(role => role === ROLES.TEACHER);

    const isManager = isAdmin || isOrganizer || isTeacher;
    const isStudent = user && user.roles.some(role => role === ROLE_STUDENT);

    const currentRoute = location.pathname.split('/')[1];
    const managementActive =
      location.pathname !== '/users/me' &&
      ['users', 'competitions', 'schools', 'contents'].includes(currentRoute);

    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <nav className="Navigation navbar navbar-expand-md bg-primary">
        <div className="container">
          <Link className="d-md-none navbar-brand" to="/">
            <FormattedMessage id="site-title" defaultMessage="Kengurukone" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
          >
            <span className="Navigation-toggler-expand text-white">
              <i className="fas fa-chevron-circle-down" />
            </span>

            <span className="Navigation-toggler-collapse text-white">
              <i className="fas fa-chevron-circle-up" />
            </span>
          </button>

          <div id="navbarNav" className="row collapse navbar-collapse">
            <ul className="col-12 col-md-auto mx-auto mx-xl-0 navbar-nav">
              <Link className="d-none d-md-block navbar-brand" to="/">
                <FormattedMessage
                  id="site-title"
                  defaultMessage="Kengurukone"
                />
              </Link>

              {user && (
                <>
                  {isStudent &&
                    this.renderNavItem(
                      'student',
                      <FormattedMessage
                        id="navigation-competition"
                        defaultMessage="Kisa"
                      />
                    )}

                  {isManager &&
                    this.renderNavItem(
                      'dashboard',
                      <FormattedMessage
                        id="navigation-dashboard"
                        defaultMessage="Aloita tästä"
                      />
                    )}

                  {isManager && (
                    <li
                      className={classNames('nav-item', 'dropdown', {
                        active: managementActive,
                      })}
                    >
                      <a
                        href="#"
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <FormattedMessage
                          id="navigation-management"
                          defaultMessage="Hallintapaneeli"
                        />
                      </a>

                      <div className="dropdown-menu">
                        {isAdmin &&
                          this.renderNavDropdownItem(
                            'users',
                            <FormattedMessage
                              id="navigation-users"
                              defaultMessage="Käyttäjät"
                            />
                          )}

                        {isManager &&
                          this.renderNavDropdownItem(
                            'competitions',
                            <FormattedMessage
                              id="navigation-competitions"
                              defaultMessage="Kilpailut"
                            />
                          )}

                        {isAdmin &&
                          this.renderNavDropdownItem(
                            'schools',
                            <FormattedMessage
                              id="navigation-schools"
                              defaultMessage="Koulut"
                            />
                          )}

                        {isAdmin &&
                          this.renderNavDropdownItem(
                            'contents',
                            <FormattedMessage
                              id="navigation-contents"
                              defaultMessage="Sisällönhallinta"
                            />
                          )}

                        {isAdmin &&
                          this.renderNavDropdownItem(
                            'files',
                            <FormattedMessage
                              id="navigation-files"
                              defaultMessage="Tiedostohallinta"
                            />
                          )}

                        {(isAdmin || isOrganizer) &&
                          this.renderNavDropdownItem(
                            'tags',
                            <FormattedMessage
                              id="navigation-tags"
                              defaultMessage="Kysymysten avainsanat"
                            />
                          )}
                      </div>
                    </li>
                  )}
                </>
              )}
            </ul>

            <ul className="col-12 col-md-auto navbar-nav mx-auto ml-xl-auto mr-xl-0 text-white">
              {user && (
                <li
                  className={classNames('nav-item', {
                    active: location.pathname === '/users/me',
                  })}
                >
                  {isStudent ? (
                    <span
                      className="d-flex align-items-center text-nowrap nav-link pointer-events-none"
                      title={user.name || user.username}
                    >
                      <i className="fas fa-user-circle fa-2x" />
                      <span className="overflow-ellipsis ml-3">
                        {user.name || user.username}
                      </span>
                    </span>
                  ) : (
                    <Link
                      className="d-flex align-items-center text-nowrap nav-link"
                      title={user.name || user.username}
                      to="/users/me"
                    >
                      <i className="fas fa-user-circle fa-2x" />
                      <span className="overflow-ellipsis ml-3">
                        {user.name || user.username}
                      </span>
                    </Link>
                  )}
                </li>
              )}

              {user &&
                !!user.schools &&
                !!user.schools.length &&
                this.renderSchools()}

              {this.renderLocale()}

              {user ? (
                <li className="nav-item">
                  <a href="#" className="nav-link" onClick={this.logout}>
                    <FormattedMessage
                      id="navigation-logout"
                      defaultMessage="Kirjaudu ulos"
                    />
                  </a>
                </li>
              ) : (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/auth/student">
                      <FormattedMessage
                        id="navigation-student"
                        defaultMessage="Aloita kisa oppilaana"
                      />
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/auth/login">
                      <FormattedMessage
                        id="navigation-login"
                        defaultMessage="Kirjaudu sisään"
                      />
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      user: selectors.currentUser(state),
      activeSchoolId: selectors.activeSchoolId(state),
    }),
    dispatch =>
      bindActionCreators(
        {
          setLocale: localeActions.set,
          setActiveSchoolId: actions.setActiveSchoolId,
          logout: actions.logout,
        },
        dispatch
      )
  )(injectIntl(Navigation))
);
