import { createAction } from 'redux-actions';

export const SCHOOLS = {
  FETCH_ALL: 'SCHOOLS/FETCH_ALL',
  RECEIVE_ALL: 'SCHOOLS/RECEIVE_ALL',
  FETCH_FUZZY: 'SCHOOLS/FETCH_FUZZY',
  RECEIVE_FUZZY: 'SCHOOLS/RECEIVE_FUZZY',
  CLEAR_FUZZY: 'SCHOOLS/CLEAR_FUZZY',
  FETCH: 'SCHOOLS/FETCH',
  RECEIVE: 'SCHOOLS/RECEIVE',
  FETCH_STATS: 'SCHOOLS/FETCH_STATS',
  RECEIVE_STATS: 'SCHOOLS/RECEIVE_STATS',
  CREATE: 'SCHOOLS/CREATE',
  CREATE_SUCCESS: 'SCHOOLS/CREATE_SUCCESS',
  REMOVE: 'SCHOOLS/REMOVE',
  REMOVE_SUCCESS: 'SCHOOLS/REMOVE_SUCCESS',
  UPDATE: 'SCHOOLS/UPDATE',
  UPDATE_SUCCESS: 'SCHOOLS/UPDATE_SUCCESS',
  ADD_USER: 'SCHOOLS/ADD_USER',
  ADD_USER_SUCCESS: 'SCHOOLS/ADD_USER_SUCCESS',
  REMOVE_USER: 'SCHOOLS/REMOVE_USER',
  REMOVE_USER_SUCCESS: 'SCHOOLS/REMOVE_USER_SUCCESS',
  ERROR: 'SCHOOLS/ERROR',
};

export const actions = {
  fetchAll: createAction(SCHOOLS.FETCH_ALL),
  receiveAll: createAction(SCHOOLS.RECEIVE_ALL),
  fetchFuzzy: createAction(SCHOOLS.FETCH_FUZZY),
  receiveFuzzy: createAction(SCHOOLS.RECEIVE_FUZZY),
  clearFuzzy: createAction(SCHOOLS.CLEAR_FUZZY),
  fetch: createAction(SCHOOLS.FETCH),
  receive: createAction(SCHOOLS.RECEIVE),
  fetchStats: createAction(SCHOOLS.FETCH_STATS),
  receiveStats: createAction(SCHOOLS.RECEIVE_STATS),
  create: createAction(SCHOOLS.CREATE),
  createSuccess: createAction(SCHOOLS.CREATE_SUCCESS),
  remove: createAction(SCHOOLS.REMOVE),
  removeSuccess: createAction(SCHOOLS.REMOVE_SUCCESS),
  update: createAction(SCHOOLS.UPDATE),
  updateSuccess: createAction(SCHOOLS.UPDATE_SUCCESS),
  addUser: createAction(SCHOOLS.ADD_USER),
  addUserSuccess: createAction(SCHOOLS.ADD_USER_SUCCESS),
  removeUser: createAction(SCHOOLS.REMOVE_USER),
  removeUserSuccess: createAction(SCHOOLS.REMOVE_USER_SUCCESS),
  error: createAction(SCHOOLS.ERROR),
};
