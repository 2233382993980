import api from '../api';

export function fetchSerieses({ competitionId, ...params }) {
  return api.get(`/competitions/${competitionId}/series`, { params });
}

export function fetchSeries({ competitionId, id, ...params }) {
  return api.get(`/competitions/${competitionId}/series/${id}`, { params });
}

export function fetchSeriesStats({ competitionId, id, ...params }) {
  return api.get(`/competitions/${competitionId}/series/${id}/stats`, {
    params,
  });
}

export function fetchSeriesParticipants({ competitionId, id, ...params }) {
  return api.get(`/competitions/${competitionId}/series/${id}/participants`, {
    params,
  });
}

export function createSeries({ competitionId, ...data }) {
  return api.post(`/competitions/${competitionId}/series`, data);
}

export function removeSeries({ competitionId, id }) {
  return api.delete(`/competitions/${competitionId}/series/${id}`);
}

export function updateSeries({ competitionId, id, ...updateData }) {
  return api.put(`/competitions/${competitionId}/series/${id}`, updateData);
}

export function updateSeriesOrder({ competitionId, serieses }) {
  return api.put(`/competitions/${competitionId}/series/order`, serieses);
}
