/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { actions as questionsActions } from '../questions';

import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

import Series from '../../models/Series';
import Pagination from '../../models/Pagination';
import School from '../../models/School';

const SERIESES = {
  FETCH_ALL: 'SERIESES/FETCH_ALL',
  RECEIVE_ALL: 'SERIESES/RECEIVE_ALL',
  FETCH: 'SERIESES/FETCH',
  RECEIVE: 'SERIESES/RECEIVE',
  FETCH_STATS: 'SERIESES/FETCH_STATS',
  RECEIVE_STATS: 'SERIESES/RECEIVE_STATS',
  FETCH_PARTICIPANTS: 'SERIESES/FETCH_PARTICIPANTS',
  RECEIVE_PARTICIPANTS: 'SERIESES/RECEIVE_PARTICIPANTS',
  CREATE: 'SERIESES/CREATE',
  CREATE_SUCCESS: 'SERIESES/CREATE_SUCCESS',
  CLONE: 'SERIESES/CLONE',
  CLONE_SUCCESS: 'SERIESES/CLONE_SUCCESS',
  REMOVE: 'SERIESES/REMOVE',
  REMOVE_SUCCESS: 'SERIESES/REMOVE_SUCCESS',
  UPDATE: 'SERIESES/UPDATE',
  UPDATE_SUCCESS: 'SERIESES/UPDATE_SUCCESS',
  UPDATE_ORDER: 'SERIESES/UPDATE_ORDER',
  UPDATE_ORDER_SUCCESS: 'SERIESES/UPDATE_ORDER_SUCCESS',
  ERROR: 'SERIESES/ERROR',
};

export const actions = {
  fetchAll: createAction(SERIESES.FETCH_ALL),
  receiveAll: createAction(SERIESES.RECEIVE_ALL),
  fetch: createAction(SERIESES.FETCH),
  receive: createAction(SERIESES.RECEIVE),
  fetchStats: createAction(SERIESES.FETCH_STATS),
  receiveStats: createAction(SERIESES.RECEIVE_STATS),
  fetchParticipants: createAction(SERIESES.FETCH_PARTICIPANTS),
  receiveParticipants: createAction(SERIESES.RECEIVE_PARTICIPANTS),
  create: createAction(SERIESES.CREATE),
  createSuccess: createAction(SERIESES.CREATE_SUCCESS),
  clone: createAction(SERIESES.CLONE),
  cloneSuccess: createAction(SERIESES.CLONE_SUCCESS),
  remove: createAction(SERIESES.REMOVE),
  removeSuccess: createAction(SERIESES.REMOVE_SUCCESS),
  update: createAction(SERIESES.UPDATE),
  updateSuccess: createAction(SERIESES.UPDATE_SUCCESS),
  updateOrder: createAction(SERIESES.UPDATE_ORDER),
  updateOrderSuccess: createAction(SERIESES.UPDATE_ORDER_SUCCESS),
  error: createAction(SERIESES.ERROR),
};

const initialState = {
  serieses: [],
  stats: {},
  participants: [],
  participantsPagination: null,
  loading: false,
  updateLoading: false,
  statsLoading: false,
  participantsLoading: false,
  cloneSuccess: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.error = null;
        draft.cloneSuccess = false;
        break;
      case SERIESES.FETCH_ALL:
        draft.loading = true;
        draft.error = null;
        break;
      case SERIESES.RECEIVE_ALL:
        draft.serieses = action.payload.map(s => new Series(s));
        draft.loading = false;
        draft.error = null;
        break;
      case SERIESES.FETCH:
        draft.loading = true;
        draft.error = null;
        break;
      case SERIESES.RECEIVE: {
        draft.loading = false;

        const serieses = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];

        serieses.forEach(s => addItem(new Series(s), draft.serieses));
        break;
      }
      case SERIESES.CLONE:
        draft.loading = true;
        draft.error = null;
        draft.cloneSuccess = false;
        break;
      case SERIESES.CLONE_SUCCESS:
        draft.loading = false;
        draft.cloneSuccess = true;
        break;
      case SERIESES.FETCH_STATS:
        draft.statsLoading = true;
        draft.error = null;
        break;
      case SERIESES.RECEIVE_STATS:
        draft.stats[action.payload.series_id] = action.payload;
        draft.statsLoading = false;
        draft.error = null;
        break;
      case SERIESES.FETCH_PARTICIPANTS:
        draft.participantsLoading = true;
        draft.error = null;
        break;
      case SERIESES.RECEIVE_PARTICIPANTS:
        draft.participants = action.payload.data.map(p => ({
          ...p,
          school: p.school && new School(p.school),
        }));

        draft.participantsPagination = new Pagination(action.payload);
        draft.participantsLoading = false;
        draft.error = null;
        break;
      case SERIESES.REMOVE:
        draft.loading = true;
        draft.error = null;
        break;
      case SERIESES.REMOVE_SUCCESS:
        draft.loading = false;
        removeItem(action.payload.id, draft.serieses);
        break;
      case SERIESES.UPDATE:
        draft.loading = true;
        draft.updateLoading = true;
        draft.error = null;
        break;
      case SERIESES.UPDATE_SUCCESS:
        draft.loading = false;
        draft.updateLoading = false;
        addItem(new Series(action.payload), draft.serieses);
        break;
      case SERIESES.UPDATE_ORDER:
        draft.loading = true;
        draft.updateLoading = true;
        draft.error = null;
        break;
      case SERIESES.UPDATE_ORDER_SUCCESS:
        draft.loading = false;
        draft.updateLoading = false;
        action.payload.forEach(s => addItem(s, draft.serieses));
        break;
      case SERIESES.ERROR:
        draft.loading = false;
        draft.updateLoading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  serieses: state => state.serieses.serieses,
  stats: state => state.serieses.stats,
  participants: state => state.serieses.participants,
  participantsPagination: state => state.serieses.participantsPagination,
  loading: state => state.serieses.loading,
  updateLoading: state => state.serieses.updateLoading,
  statsLoading: state => state.serieses.statsLoading,
  participantsLoading: state => state.serieses.participantsLoading,
  cloneSuccess: state => state.serieses.cloneSuccess,
  error: state => state.serieses.error,
};

export function* fetchSerieses(action) {
  try {
    const serieses = yield call(api.fetchSerieses, action.payload);

    const { include } = action.payload;
    if (include) {
      const includes = include.split(',');
      if (includes.includes('questions')) {
        const questions = [].concat(...serieses.map(s => s.questions));
        yield put(questionsActions.receive(questions));
      }
    }

    yield put(actions.receiveAll(serieses));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchSeries(action) {
  try {
    const series = yield call(api.fetchSeries, action.payload);

    const { include } = action.payload;
    if (include) {
      const includes = include.split(',');
      if (includes.includes('questions')) {
        put(questionsActions.receive(series.questions));
      }
    }

    yield put(actions.receive(series));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchSeriesStats(action) {
  try {
    const stats = yield call(api.fetchSeriesStats, action.payload);
    yield put(actions.receiveStats(stats));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* fetchSeriesParticipants(action) {
  try {
    const result = yield call(api.fetchSeriesParticipants, action.payload);
    yield put(actions.receiveParticipants(result));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createSeries(action) {
  try {
    const series = yield call(api.createSeries, action.payload);
    yield put(actions.receive(series));
    yield put(
      replace(`/competitions/${series.competition_id}/series/${series.id}`)
    );
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* cloneSerieses(action) {
  try {
    const { source, target } = action.payload;
    const serieses = yield call(api.fetchSerieses, {
      competitionId: source,
    });

    const newSerieses = yield all(
      serieses.map(series =>
        call(api.createSeries, {
          ...series,
          competitionId: target,
          competition_id: target,
          question_ids: [],
        })
      )
    );

    yield put(actions.receive(newSerieses));
    yield put(actions.cloneSuccess());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* removeSeries(action) {
  try {
    yield call(api.removeSeries, action.payload);
    yield put(actions.removeSuccess(action.payload));
    yield put(replace('/competitions'));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateSeries(action) {
  try {
    const serieses = yield select(selectors.serieses);
    const series = serieses.find(u => u.id === action.payload.id);
    const updateData = {
      ...series,
      ...action.payload,
    };

    const updatedSeries = yield call(api.updateSeries, updateData);
    yield put(actions.updateSuccess(updatedSeries));
    yield put(
      replace(`/competitions/${series.competition_id}/series/${series.id}`)
    );
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* updateSeriesOrder(action) {
  try {
    const serieses = yield call(api.updateSeriesOrder, action.payload);
    yield put(actions.updateOrderSuccess(serieses));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* seriesSaga() {
  yield all([
    yield takeLatest(SERIESES.FETCH_ALL, fetchSerieses),
    yield takeLatest(SERIESES.FETCH, fetchSeries),
    yield takeLatest(SERIESES.FETCH_STATS, fetchSeriesStats),
    yield takeLatest(SERIESES.FETCH_PARTICIPANTS, fetchSeriesParticipants),
    yield takeEvery(SERIESES.CREATE, createSeries),
    yield takeEvery(SERIESES.CLONE, cloneSerieses),
    yield takeEvery(SERIESES.REMOVE, removeSeries),
    yield takeEvery(SERIESES.UPDATE, updateSeries),
    yield takeEvery(SERIESES.UPDATE_ORDER, updateSeriesOrder),
  ]);
}
