import { all } from 'redux-saga/effects';
import { initSaga } from './init';
import { messageSaga } from './message';
import { userSaga } from './user';
import { usersSaga } from './users';
import { competitionSaga } from './competitions';
import { seriesSaga } from './serieses';
import { schoolsSaga } from './schools';
import { groupSaga } from './groups';
import { questionSaga } from './questions';
import { choiceSaga } from './choices';
import { answerSaga } from './answers';
import { fileSaga } from './files';
import { contentSaga } from './contents';
import { tagSaga } from './tags';

export default function* rootSaga() {
  yield all([
    initSaga(),
    messageSaga(),
    userSaga(),
    usersSaga(),
    competitionSaga(),
    seriesSaga(),
    schoolsSaga(),
    groupSaga(),
    questionSaga(),
    choiceSaga(),
    answerSaga(),
    fileSaga(),
    contentSaga(),
    tagSaga(),
  ]);
}
