/* eslint-disable no-param-reassign */
import produce from 'immer';
import { createAction } from 'redux-actions';
import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import { actions as userActions, selectors as userSelectors } from '../user';

import { SCHOOLS, actions as schoolsActions } from '../schools/actions';

import User from '../../models/User';
import Pagination from '../../models/Pagination';
import { COMMON, addItem, removeItem } from '../common';
import * as api from './api';

const USERS = {
  FETCH_ALL: 'USERS/FETCH_ALL',
  RECEIVE_ALL: 'USERS/RECEIVE_ALL',
  FETCH_UNVERIFIED: 'USERS/FETCH_UNVERIFIED',
  RECEIVE_UNVERIFIED: 'USERS/RECEIVE_UNVERIFIED',
  FETCH: 'USERS/FETCH',
  RECEIVE: 'USERS/RECEIVE',
  CREATE: 'USERS/CREATE',
  CREATE_SUCCESS: 'USERS/CREATE_SUCCESS',
  REMOVE: 'USERS/REMOVE',
  REMOVE_SUCCESS: 'USERS/REMOVE_SUCCESS',
  UPDATE: 'USERS/UPDATE',
  UPDATE_SUCCESS: 'USERS/UPDATE_SUCCESS',
  IMPERSONATE: 'USERS/IMPERSONATE',
  IMPERSONATE_SUCCESS: 'USERS/IMPERSONATE_SUCCESS',
  REJECT: 'USERS/REJECT',
  REJECT_SUCCESS: 'USERS/REJECT_SUCCESS',
  VERIFY: 'USERS/VERIFY',
  VERIFY_SUCCESS: 'USERS/VERIFY_SUCCESS',
  SEND_MAIL: 'USERS/SEND_MAIL',
  SEND_MAIL_SUCCESS: 'USERS/SEND_MAIL_SUCCESS',
  ERROR: 'USERS/ERROR',
};

export const actions = {
  fetchAll: createAction(USERS.FETCH_ALL),
  receiveAll: createAction(USERS.RECEIVE_ALL),
  fetchUnverified: createAction(USERS.FETCH_UNVERIFIED),
  receiveUnverified: createAction(USERS.RECEIVE_UNVERIFIED),
  fetch: createAction(USERS.FETCH),
  receive: createAction(USERS.RECEIVE),
  create: createAction(USERS.CREATE),
  createSuccess: createAction(USERS.CREATE_SUCCESS),
  remove: createAction(USERS.REMOVE),
  removeSuccess: createAction(USERS.REMOVE_SUCCESS),
  update: createAction(USERS.UPDATE),
  updateSuccess: createAction(USERS.UPDATE_SUCCESS),
  impersonate: createAction(USERS.IMPERSONATE),
  impersonateSuccess: createAction(USERS.IMPERSONATE_SUCCESS),
  reject: createAction(USERS.REJECT),
  rejectSuccess: createAction(USERS.REJECT_SUCCESS),
  verify: createAction(USERS.VERIFY),
  verifySuccess: createAction(USERS.VERIFY_SUCCESS),
  sendMail: createAction(USERS.SEND_MAIL),
  sendMailSuccess: createAction(USERS.SEND_MAIL_SUCCESS),
  error: createAction(USERS.ERROR),
};

const initialState = {
  users: [],
  pagination: null,
  unverifiedUsers: [],
  rejectedUserIds: [],
  loading: false,
  updateLoading: false,
  updateSuccess: false,
  rejectLoading: false,
  rejectSuccess: false,
  verifyLoading: false,
  verifySuccess: false,
  sendMailSuccess: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON.NAVIGATE:
        draft.rejectedUserIds = [];
        draft.updateSuccess = false;
        draft.rejectSuccess = false;
        draft.verifySuccess = false;
        draft.error = null;
        break;
      case USERS.FETCH_ALL:
        draft.loading = true;
        break;
      case USERS.RECEIVE_ALL:
        draft.users = action.payload.data.map(u => new User(u));
        draft.pagination = new Pagination(action.payload);
        draft.loading = false;
        draft.error = null;
        break;
      case USERS.FETCH_UNVERIFIED:
        draft.loading = true;
        break;
      case USERS.RECEIVE_UNVERIFIED:
        draft.unverifiedUsers = action.payload.data.map(u => new User(u));
        draft.loading = false;
        draft.error = null;
        break;
      case USERS.FETCH:
        draft.loading = true;
        break;
      case USERS.RECEIVE:
        draft.loading = false;
        draft.error = null;
        addItem(new User(action.payload), draft.users);
        break;
      case USERS.REMOVE:
        draft.loading = true;
        break;
      case USERS.REMOVE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        removeItem(action.payload, draft.users);
        break;
      case USERS.IMPERSONATE:
        draft.loading = true;
        break;
      case USERS.IMPERSONATE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        break;
      case USERS.UPDATE:
        draft.loading = true;
        draft.updateLoading = true;
        draft.updateSuccess = false;
        break;
      case USERS.UPDATE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.updateLoading = false;
        draft.updateSuccess = true;
        addItem(new User(action.payload), draft.users);
        break;
      case USERS.REJECT:
        draft.rejectLoading = true;
        draft.rejectSuccess = false;
        break;
      case USERS.REJECT_SUCCESS:
        draft.rejectLoading = false;
        draft.rejectSuccess = true;
        draft.error = null;

        if (!draft.rejectedUserIds.find(u => u === action.payload)) {
          draft.rejectedUserIds.push(action.payload);
        }

        break;
      case USERS.VERIFY:
        draft.verifyLoading = true;
        draft.verifySuccess = false;
        break;
      case USERS.VERIFY_SUCCESS:
        draft.verifyLoading = false;
        draft.verifySuccess = true;
        draft.error = null;
        break;
      case USERS.SEND_MAIL:
        draft.loading = true;
        draft.sendMailSuccess = false;
        break;
      case USERS.SEND_MAIL_SUCCESS:
        draft.loading = false;
        draft.sendMailSuccess = true;
        draft.error = null;
        break;
      case USERS.ERROR:
        draft.loading = false;
        draft.sendMailSuccess = false;
        draft.updateLoading = false;
        draft.rejectLoading = false;
        draft.verifyLoading = false;
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const selectors = {
  users: state => state.users.users,
  pagination: state => state.users.pagination,
  unverifiedUsers: state => state.users.unverifiedUsers,
  rejectedUserIds: state => state.users.rejectedUserIds,
  loading: state => state.users.loading,
  updateLoading: state => state.users.updateLoading,
  updateSuccess: state => state.users.updateSuccess,
  rejectLoading: state => state.users.rejectLoading,
  rejectSuccess: state => state.users.rejectSuccess,
  verifyLoading: state => state.users.verifyLoading,
  verifySuccess: state => state.users.verifySuccess,
  sendMailSuccess: state => state.users.sendMailSuccess,
  error: state => state.users.error,
};

function* fetchUsers(action) {
  try {
    const result = yield call(api.fetchUsers, action.payload || {});
    yield put(actions.receiveAll(result));
  } catch (error) {
    yield put(actions.error(error));
  }
}

function* fetchUnverifiedUsers(action) {
  const payload = { is_verified: false, ...action.payload };

  try {
    const result = yield call(api.fetchUsers, payload);
    yield put(actions.receiveUnverified(result));
  } catch (error) {
    yield put(actions.error(error));
  }
}

function* fetchUser(action) {
  try {
    const user = yield call(api.fetchUser, action.payload);
    yield put(actions.receive(user));
  } catch (error) {
    yield put(actions.error(error));
  }
}

function* createUser(action) {
  try {
    const user = yield call(api.createUser, action.payload);
    yield put(actions.receive(user));
    yield put(replace(`/users/${user.id}`));
  } catch (error) {
    yield put(actions.error(error));
  }
}

function* removeUser(action) {
  try {
    yield call(api.removeUser, action.payload);
    yield put(actions.removeSuccess(action.payload));
    yield put(push('/users'));
  } catch (error) {
    yield put(actions.error(error));
  }
}

function* impersonateUser(action) {
  try {
    yield call(api.impersonateUser, action.payload);
    yield put(actions.impersonateSuccess(action.payload));
    yield put(userActions.fetch());
  } catch (error) {
    yield put(actions.error(error));
  }
}

function* updateUser(action) {
  try {
    const currentUser = yield select(userSelectors.currentUser);

    const users = yield select(selectors.users);
    const user = users.find(u => u.id === action.payload.id);

    const updateData = {
      ...user,
      ...action.payload,
    };

    const updatedUser = yield call(api.updateUser, user.id, updateData);

    if (currentUser.id === user.id) {
      yield put(userActions.receive(updatedUser));
    }

    yield put(actions.updateSuccess(updatedUser));
  } catch (error) {
    yield put(actions.error(error));
  }
}

function* rejectUser(action) {
  try {
    yield call(api.rejectUser, action.payload);
    yield put(actions.rejectSuccess(action.payload));
  } catch (error) {
    yield put(actions.error(error));
  }
}

function* verifyUser(action) {
  try {
    const { userId, schoolId } = action.payload;

    const payload = { id: userId, is_verified: true };
    yield put(actions.update(payload));

    const updateAction = yield take([actions.updateSuccess, actions.error]);
    if (updateAction.type === USERS.ERROR) return;

    if (schoolId) {
      yield put(schoolsActions.addUser({ id: schoolId, user_id: userId }));

      const addAction = yield take([
        schoolsActions.addUserSuccess,
        schoolsActions.error,
      ]);

      if (addAction.type === SCHOOLS.ERROR) throw addAction.payload;
    }

    yield put(actions.verifySuccess());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* sendMail(action) {
  try {
    yield call(api.sendMail, action.payload);
    yield put(actions.sendMailSuccess());
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* usersSaga() {
  yield all([
    yield takeLatest(USERS.FETCH_ALL, fetchUsers),
    yield takeLatest(USERS.FETCH_UNVERIFIED, fetchUnverifiedUsers),
    yield takeLatest(USERS.FETCH, fetchUser),
    yield takeEvery(USERS.CREATE, createUser),
    yield takeEvery(USERS.REMOVE, removeUser),
    yield takeEvery(USERS.UPDATE, updateUser),
    yield takeEvery(USERS.IMPERSONATE, impersonateUser),
    yield takeEvery(USERS.REJECT, rejectUser),
    yield takeEvery(USERS.VERIFY, verifyUser),
    yield takeEvery(USERS.SEND_MAIL, sendMail),
  ]);
}
