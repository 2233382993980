import api from '../api';

export function fetchTags() {
  return api.get('/tags');
}

export function fetchTag({ id }) {
  return api.get(`/tags/${id}`);
}

export function createTag(data) {
  return api.post('/tags', data);
}

export function removeTag({ id }) {
  return api.delete(`/tags/${id}`);
}

export function updateTag({ id, ...updateData }) {
  return api.put(`/tags/${id}`, updateData);
}
