import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const LocalizedBreadcrumb = props => props.intl.formatMessage(props.message);

LocalizedBreadcrumb.propTypes = {
  intl: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
};

export default injectIntl(LocalizedBreadcrumb);
