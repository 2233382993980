import api from '../api';

export function fetchCompetitions({
  type,
  name,
  is_public,
  is_archived,
  include,
}) {
  return api.get('/competitions', {
    params: {
      type,
      name,
      is_public,
      is_archived,
      include,
    },
  });
}

export function fetchCompetition(id) {
  return api.get(`/competitions/${id}`);
}

export function createCompetition(data) {
  return api.post('/competitions', {
    type: data.type,
    name: data.name,
    description: data.description,
    logo: data.logo,
    is_archived: data.isArchived,
    web_public_at: data.webPublicAt,
    pdf_public_at: data.pdfPublicAt,
  });
}

export function removeCompetition(id) {
  return api.delete(`/competitions/${id}`);
}

export function updateCompetition(id, updateData) {
  return api.put(`/competitions/${id}`, updateData);
}

export function fetchCompetitionSchools({ competitionId }) {
  return api.get(`/competitions/${competitionId}/schools`);
}

export function sendMail({ id, ...data }) {
  return api.post(`/competitions/${id}/mail`, data);
}
