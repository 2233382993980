import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

/**
 * Route component that handles lazy loaded components.
 * By default uses react-router's Route, but you can give your own route
 * component.
 */

const SuspenseRoute = ({
  fallback: Fallback,
  lazyComponent: LazyComponent,
  routeComponent: RouteComponent,
  ...rest
}) => {
  return (
    <RouteComponent
      {...rest}
      render={props => (
        <Suspense fallback={<Fallback />}>
          <LazyComponent {...props} />
        </Suspense>
      )}
    />
  );
};

SuspenseRoute.propTypes = {
  fallback: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  lazyComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  routeComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.func,
  ]),
};

SuspenseRoute.defaultProps = {
  routeComponent: Route,
};

export default SuspenseRoute;
